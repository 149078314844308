<template>
  <div
    v-if="!categoryId || categoryId === ''"
    class="error-state alert alert-danger"
  >
    <el-empty :description="$t('EXAM_VAULT_NO_CATEGORY_DESC')" />
  </div>

  <div v-else class="exam-vault-component" v-loading="isLoading">
    <!-- Filters Section -->
    <div class="top-bar">
      <div class="filters-section">
        <div class="search-box filter-control">
          <BaseInputText
            type="text"
            v-model="searchQuery"
            @keyup.enter="updateSearchFilter"
            :placeholder="$t('SEARCH')"
            suffixIcon="search"
            iconColor="#1CAB36"
            class="form-control"
          />
        </div>

        <!-- Subject Filter -->
        <BaseInputSelect
          v-model="selectedSubject"
          @update="updateFilter('subject', selectedSubject)"
          :placeholder="$t('SUBJECT')"
          :options="config?.subjects"
          :optValue="`${lang}Value`"
          :clearable="true"
          class="filter-control"
        />

        <!-- Product Type Filter -->
        <BaseInputSelect
          v-model="selectedProductType"
          @update="updateFilter('productType', selectedProductType)"
          :placeholder="$t('TYPE')"
          :options="config?.productTypes"
          :optValue="`${lang}Value`"
          :clearable="true"
          class="filter-control"
        />

        <!-- Skill Filter -->
        <BaseInputSelect
          v-model="selectedSkill"
          @update="updateFilter('skill', selectedSkill)"
          :placeholder="$t('SKILL')"
          :options="config?.skills"
          :optValue="`${lang}Value`"
          :clearable="true"
          class="filter-control"
        />
      </div>

      <div class="right-bar">
        <BaseButton
          v-if="editable && editPermission"
          icon="add_circle"
          :label="$t('CREATE')"
          @click="handleEditExam('create')"
        />
      </div>
    </div>

    <!-- Error State -->
    <div v-if="localError" class="error-state alert alert-danger">
      An error occurred while loading exams. Please try again.
    </div>

    <!-- Empty State -->
    <div v-else-if="!exams.length" class="empty-state">
      <el-empty />
    </div>

    <!-- Exams List -->
    <div v-else class="exams-container">
      <!-- Table Header -->
      <el-row class="exam-header-row">
        <el-col :span="selectable ? 12 : 11" class="title-col">{{
          $t('COMMON_TITLE')
        }}</el-col>
        <el-col :span="selectable ? 4 : 3" class="type-col">{{
          $t('TYPE')
        }}</el-col>
        <el-col :span="selectable ? 4 : 3" class="skill-col">{{
          $t('SKILL')
        }}</el-col>
        <el-col :span="selectable ? 4 : 3" class="count-col">{{
          $t('QUESTION')
        }}</el-col>
        <el-col v-if="!selectable" :span="4" class="actions-col"></el-col>
      </el-row>

      <div class="exams-list">
        <div
          v-for="exam in exams"
          :key="exam._id"
          :style="{ cursor: selectable ? 'pointer' : 'default' }"
          @click="selectable ? selectExam(exam) : null"
        >
          <el-row class="exam-item">
            <el-col
              :xs="24"
              :sm="24"
              :md="selectable ? 12 : 11"
              :lg="selectable ? 12 : 11"
              :xl="selectable ? 12 : 11"
              class="title-col"
            >
              <h3 class="exam-title">
                <span v-html="highlightText(exam.title)"></span>
              </h3>
              <div class="exam-details">
                <p
                  v-if="exam.description"
                  class="exam-intro"
                  v-html="highlightText(truncateText(exam.description, 200))"
                ></p>
                <div class="tags-container">
                  <span
                    v-for="(tag, idx) in exam.tags"
                    :key="idx"
                    class="tag"
                    >{{ tag }}</span
                  >
                </div>
              </div>
              <div class="assign-homework">
                <el-button
                  type="text"
                  @click="handleAssignHomework(exam)"
                  :disabled="!exam.questionCount"
                  v-if="!selectable"
                >
                  {{ $t('ASSIGN_NEW') }}
                </el-button>
              </div>
            </el-col>
            <el-col
              :xs="12"
              :sm="6"
              :md="selectable ? 4 : 3"
              :lg="selectable ? 4 : 3"
              :xl="selectable ? 4 : 3"
              class="type-col"
            >
              <span v-if="exam.productType" class="badge badge-dark">{{
                getConfigName(exam.productType)
              }}</span>
              <span v-else>-</span>
            </el-col>
            <el-col
              :xs="12"
              :sm="6"
              :md="selectable ? 4 : 3"
              :lg="selectable ? 4 : 3"
              :xl="selectable ? 4 : 3"
              class="skill-col"
            >
              <span class="badge badge-dark">{{
                getConfigName(exam.skill)
              }}</span>
            </el-col>
            <el-col
              :xs="12"
              :sm="6"
              :md="selectable ? 4 : 3"
              :lg="selectable ? 4 : 3"
              :xl="selectable ? 4 : 3"
              class="count-col"
            >
              <div class="count-info">
                <div class="count-item">
                  <!-- <i class="el-icon-document"></i> -->
                  <span>{{
                    exam.questionGroupIds ? exam.questionGroupIds.length : 0
                  }}</span>
                  <small>{{ $t('EXERCISE') }}</small>
                </div>
                <div class="count-item">
                  <!-- <i class="el-icon-question"></i> -->
                  <span>{{ exam.questionCount || 0 }}</span>
                  <small>{{ $t('QUESTION') }}</small>
                </div>
              </div>
            </el-col>
            <el-col
              v-if="!selectable"
              :xs="24"
              :sm="24"
              :md="4"
              :lg="4"
              :xl="4"
              class="actions-col"
            >
              <el-tooltip v-if="editable && editPermission" content="Edit">
                <el-button type="text" @click="handleEditExam('update', exam)">
                  <!-- <span style="color: #919191">{{ $t('EDIT') }}</span> -->
                  <i class="el-icon-edit"></i>
                </el-button>
              </el-tooltip>

              <el-tooltip v-if="editable && editPermission" content="Delete">
                <el-button type="text" @click="handleDeleteExam(exam)">
                  <i class="el-icon-delete"></i>
                </el-button>
              </el-tooltip>

              <el-tooltip
                v-if="editable && editPermission"
                content="Edit questions"
              >
                <el-button type="text">
                  <router-link
                    :to="`/kho-hoc-lieu/${exam._id}`"
                    class="btn btn-outline-primary"
                  >
                    <!-- {{ $t('VIEW_DETAIL') }} -->
                    <i class="el-icon-question"></i>
                  </router-link>
                </el-button>
              </el-tooltip>

              <el-tooltip content="Preview">
                <el-button type="text" @click.prevent="handlePreviewExam(exam)">
                  <i class="el-icon-view"></i>
                </el-button>
              </el-tooltip>
            </el-col>
          </el-row>
        </div>
      </div>

      <div class="w-100" v-if="pagination.total > 0" style="padding-top: 24px">
        <el-pagination
          :hide-on-single-page="true"
          :current-page="pagination.page"
          :page-size="pagination.limit"
          :total="pagination.total"
          background
          layout="prev, pager, next, total"
          @current-change="onPageChange"
        />
      </div>
    </div>

    <el-dialog
      title="Preview"
      :visible.sync="previewDialog.active"
      width="70%"
      append-to-body
    >
      <div class="preview-exam" v-loading="previewDialog.loading">
        <ExamPreview
          v-if="previewDialog.exam"
          :questionGroups="previewDialog.exam?.questionGroups || []"
        />
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import examVaultService from '@/api/exam-vault'
import ExamPreview from './ExamPreview.vue'

export default {
  name: 'ExamVaultComponent',
  components: {
    ExamPreview
  },
  props: {
    categoryId: {
      type: String || null,
      default: null,
      required: false
    },
    editable: {
      type: Boolean,
      default: false
    },
    editPermission: {
      type: Boolean,
      default: false
    },
    selectable: {
      type: Boolean,
      default: false
    },
    ownerCategory: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      previewDialog: {
        active: false,
        loading: false,
        exam: null
      },
      selectedSubject: null,
      selectedCategory: null,
      selectedProductType: null,
      selectedSkill: null,
      searchQuery: '',
      examDialog: {
        active: false
      },
      lang: 'vi',
      // Local storage for exams
      localExams: [],
      localLoading: false,
      localError: null,
      localPagination: {
        page: 1,
        limit: 10,
        total: 0
      },
      localFilters: {
        subject: null,
        category: null,
        productType: null,
        skill: null,
        search: ''
      }
    }
  },

  computed: {
    ...mapState('exam-vault', ['config']),
    ...mapGetters('exam-vault', ['hasError']),

    // Replace store exams with local exams
    exams() {
      return this.localExams
    },

    // Local computed properties for loading and pagination
    isLoading() {
      return this.localLoading
    },

    pagination() {
      return this.localPagination
    }
  },

  created() {
    this.fetchExamConfig()
    this.lang = this.$i18n.locale
    // Initial fetch of exams when component is created
    // this.fetchLocalExams()
  },

  watch: {
    categoryId: {
      handler: function () {
        if (this.categoryId && this.categoryId !== '') {
          this.localPagination.page = 1
          this.fetchLocalExams()
        }
      },
      immediate: true
    }
  },

  methods: {
    ...mapActions('exam-vault', ['fetchExamConfig']),

    handleAssignHomework(exam) {
      this.$emit('assignHomework', exam)
    },

    async handlePreviewExam(exam) {
      this.previewDialog.active = true
      try {
        this.previewDialog.loading = true
        const res = await examVaultService.getExamById(exam._id)
        this.previewDialog.exam = res.data.exam
      } catch (error) {
        console.error('Failed to preview exam', error)
      } finally {
        this.previewDialog.loading = false
      }
    },

    // New method to fetch exams directly from API
    async fetchLocalExams() {
      this.localExams = []
      this.localLoading = true
      try {
        const query = {
          ...this.localFilters,
          categoryId: this.categoryId,
          page: this.localPagination.page,
          limit: this.localPagination.limit
        }

        if (
          this.ownerCategory &&
          ['Teacher', 'School', 'Group'].includes(this.ownerCategory)
        ) {
          query.ownerCategory = this.ownerCategory
        }

        const response = await examVaultService.getExams(query)
        this.localExams = response.data.list

        // Update pagination
        this.localPagination = {
          page: parseInt(response.data.pagination.page),
          limit: parseInt(response.data.pagination.limit),
          total: parseInt(response.data.pagination.total)
        }

        this.localError = null
      } catch (error) {
        this.localError = error.message || 'Failed to fetch exams'
      } finally {
        this.localLoading = false
      }
    },

    async onPageChange(page) {
      this.localPagination.page = page
      await this.fetchLocalExams()
    },

    handleEditExam(event, data) {
      this.$emit('editExam', event, data)
    },

    handleDeleteExam(data) {
      const self = this
      this.$confirm(
        this.$t(
          'CONFIRM_DELETE_EXAM',
          'Are you sure you want to delete this exam?'
        ),
        this.$t('WARNING', 'Warning'),
        {
          confirmButtonText: this.$t('DELETE', 'Delete'),
          cancelButtonText: this.$t('CANCEL', 'Cancel'),
          type: 'warning'
        }
      )
        .then(() => {
          self.$emit('deleteExam', data)
        })
        .catch(() => {
          // User canceled the action
          console.log('Delete canceled')
        })
    },

    // Method to refresh exam list after create/update
    refreshExams() {
      this.fetchLocalExams()
    },

    updateFilter(key, value) {
      this.localFilters[key] = value
      this.localPagination.page = 1 // Reset to first page when filter changes
      this.fetchLocalExams()
    },

    updateSearchFilter() {
      this.localFilters.search = this.searchQuery
      this.localPagination.page = 1
      this.fetchLocalExams()
    },

    getConfigName(obj) {
      return obj ? obj[`${this.lang}Value`] : ''
    },

    truncateText(text, maxLength) {
      if (!text) return ''
      return text.length > maxLength
        ? `${text.substring(0, maxLength)}...`
        : text
    },

    highlightText(text) {
      if (!text || !this.localFilters.search) return text

      const searchTerms = this.localFilters.search
        .split(/\s+/)
        .filter((term) => term.length > 0)

      if (searchTerms.length === 0) return text

      let result = text
      searchTerms.forEach((term) => {
        const regex = new RegExp(`(${term})`, 'i')
        result = result.replace(
          regex,
          '<span class="highlighted-text">$1</span>'
        )
      })

      return result
    },

    // New method to handle exam selection when selectable is true
    async selectExam(exam) {
      if (this.selectable) {
        // Element confirm dialog
        return this.$confirm(
          this.$t('CONFIRM_SELECT_EXAM'),
          this.$t('CONFIRM'),
          {
            confirmButtonText: this.$t('CONFIRM'),
            cancelButtonText: this.$t('CANCEL'),
            type: 'warning'
          }
        )
          .then(async () => {
            const examResponse = await examVaultService.getExamById(exam._id)
            this.$emit('selectExam', examResponse.data?.exam)
          })
          .catch(() => {})
      }
    }
  }
}
</script>

<style scoped lang="scss">
.exam-vault-component {
  .top-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 42px;

    .filters-section {
      display: flex;
      width: 100%;
      justify-content: start;
      flex-wrap: wrap;

      .search-box {
        min-width: 250px;
      }

      .filter-control {
        margin-right: 10px;
        margin-bottom: 10px;
      }
    }
  }

  .error-state,
  .empty-state {
    text-align: center;
    padding: 50px;
  }

  .exams-container {
    margin-top: 24px;
  }

  .exam-header-row {
    background-color: #f8f9fa;
    border: 1px solid #dee2e6;
    border-radius: 5px 5px 0 0;
    font-weight: bold;
    padding: 12px 15px;
    margin: 0;
    align-items: center;
  }

  .exams-list {
    display: flex;
    flex-direction: column;
  }

  .exam-item {
    background-color: #fff;
    border: 1px solid #ddd;
    border-top: none;
    padding: 15px;
    margin: 0;
    display: flex;
    align-items: center;

    &:last-child {
      border-radius: 0 0 5px 5px;
    }

    &:hover {
      background-color: #f8f9fa;
    }

    &.selectable {
      cursor: pointer;
    }
  }

  .title-col {
    padding: 0 10px;
    text-align: left;
  }

  .type-col,
  .skill-col {
    padding: 0 10px;
    display: flex;
    justify-content: center;
  }

  .actions-col {
    padding: 0 10px;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }

  .exam-title {
    margin: 0;
    font-size: 16px;
    font-weight: 600;
    width: 100%;
    text-align: left;
  }

  .exam-details {
    width: 100%;
    margin-top: 10px;
  }

  .exam-intro {
    margin: 0 0 5px 0;
    color: #555;
    font-size: 14px;
    text-align: left;
  }

  .badge {
    padding: 4px 8px;
    border-radius: 4px;
    font-size: 12px;
    display: inline-block;
    font-weight: normal;
  }

  .badge-primary {
    background-color: #e6f7ff;
    color: #1890ff;
  }

  .badge-secondary {
    background-color: #f6f6f6;
    color: #666;
  }

  .badge-info {
    background-color: #e6fffb;
    color: #13c2c2;
  }

  .badge-dark {
    background-color: #f2f2f2;
    color: #333;
  }

  .tags-container {
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
  }

  .tag {
    background-color: #e9ecef;
    border-radius: 15px;
    padding: 2px 8px;
    font-size: 12px;
    margin-right: 5px;
    margin-bottom: 5px;
    display: inline-block;
  }

  .count-col {
    padding: 0 10px;
    display: flex;
    justify-content: center;
  }

  .count-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
  }

  .count-item {
    display: flex;
    align-items: center;
    gap: 5px;
    color: #606266;
    font-size: 13px;

    i {
      color: #909399;
    }

    span {
      font-weight: bold;
    }

    small {
      color: #909399;
      font-size: 11px;
    }
  }

  @media (max-width: 768px) {
    .exam-header-row {
      display: none;
    }

    .exam-item {
      padding: 15px 10px;
      margin-bottom: 10px;
      border: 1px solid #ddd;
      border-radius: 5px;
    }

    .title-col,
    .type-col,
    .skill-col {
      margin-bottom: 10px;
    }

    .type-col::before,
    .skill-col::before,
    .count-col::before {
      content: attr(data-label);
      font-weight: 600;
      margin-right: 5px;
    }

    .actions-col {
      justify-content: flex-start;
      margin-top: 10px;
      padding-top: 10px;
      border-top: 1px solid #eee;
    }

    .count-info {
      flex-direction: row;
      gap: 15px;
    }
  }

  :deep(.highlighted-text) {
    background-color: #ffeb3b;
    font-weight: bold;
    padding: 0 2px;
    border-radius: 2px;
  }
}
</style>
