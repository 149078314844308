<template>
  <div class="question-answer-preview">
    <div v-if="this.questionType === 'MultiChoice'" class="multiple-choice">
      <div class="answers md-layout md-gutter">
        <div
          class="answer md-layout-item md-size-50 answer-item"
          v-for="(ans, index) in answersTemp"
          :key="index"
        >
          <md-radio v-model="rightTemp" :value="index" disabled />
          <BaseInputQuestionText v-model="answersTemp[index]" disabled />
        </div>
      </div>
    </div>

    <div v-if="this.questionType === 'ShortAnswer'" class="short-answer">
      <div class="answers md-layout md-gutter">
        <div
          class="answer md-layout-item md-size-50 answer-item"
          v-for="(ans, index) in rightTemp"
          :key="index"
        >
          <BaseInputQuestionText
            v-model="rightTemp[index]"
            :suffixIcon="''"
            disabled
          />
        </div>
      </div>
    </div>

    <div v-if="this.questionType === 'StringSorting'" class="string-sorting">
      <div class="answers">
        <div class="answer answer-item">
          <BaseInputQuestionText v-model="rightTemp" disabled />
        </div>
      </div>
    </div>

    <div v-if="this.questionType === 'CompleteSentence'" class="speaking">
      <div class="answers">
        <div class="answer answer-item">
          <div class="tag-list">
            <template v-for="(item, index) in answersTemp.phrase.split(' ')">
              <span
                :key="index"
                class="classification-tag"
                :style="
                  index === rightTemp[0]
                    ? 'background-color: #1CAB36; color: white;'
                    : 'margin-right: 5px'
                "
                >{{ item }}</span
              >
              <span
                :key="index"
                v-if="index === rightTemp[0] && fakeChoices && fakeChoices[0]"
              >
                ||
              </span>
              <span
                :key="index"
                class="classification-tag"
                style="
                  background-color: #ff5252;
                  color: white;
                  margin-right: 5px;
                "
                v-if="index === rightTemp[0] && fakeChoices && fakeChoices[0]"
                >{{ fakeChoices[0] }}
              </span>
            </template>
          </div>
        </div>

        <div class="note" style="padding-top: 14px; font-size: 12px">
          <span style="font-weight: bold">Chú ý: </span> Dạng bài này là lựa
          chọn giữa đáp án đúng và đáp án sai.
        </div>
      </div>
    </div>

    <div v-if="this.questionType === 'Speaking'" class="speaking">
      <div class="answers">
        <div class="answer answer-item">Phát âm từ : {{ rightTemp }}</div>
      </div>
    </div>

    <div v-if="this.questionType === 'Classification'" class="classification">
      <div class="answers md-layout md-gutter">
        <div class="classification-fields md-layout-item answer-item">
          <BaseInputQuestionSelect
            key="select1"
            v-model="answersTemp.classificationFields"
            :multiple="true"
            :allowCreate="true"
            :filterable="true"
            :createTag="true"
            disabled
            style="width: 100%"
          />
        </div>
      </div>

      <div class="answers md-layout md-gutter">
        <div class="classification-fields md-layout-item answer-item">
          <BaseInputQuestionSelect
            key="select2"
            v-model="answersTemp.list"
            :multiple="true"
            :allowCreate="true"
            :filterable="true"
            :createTag="true"
            disabled
            style="width: 100%"
          />
        </div>
      </div>

      <div class="answers">
        <div
          class="classification-fields answer-item"
          v-for="(item, index) in answersTemp.classificationFields"
          :key="`classification-item-${index}`"
        >
          <span class="tag-list">
            <div class="classification-tag" v-html="item"></div>
          </span>
          <BaseInputQuestionSelect
            v-model="rightTemp[index]"
            :multiple="true"
            :allowCreate="false"
            :filterable="true"
            :createTag="true"
            disabled
            :options="
              answersTemp.list
                ? answersTemp.list.map((ans, index) => {
                    return { _id: index, name: ans }
                  })
                : []
            "
            style="width: 100%"
          />
        </div>
      </div>
    </div>

    <div v-if="this.questionType === 'UnderlineWord'" class="underline-word">
      <div class="answers">
        <div
          v-if="answersTemp.type === 'word' && answersTemp.phrase"
          class="answer answer-item underline-right"
        >
          <span
            class="underline-tag"
            v-for="(item, index) in answersTemp.phrase.split(' ')"
            :key="`underline-tag-${index}`"
            :class="rightTemp.includes(index) ? 'selected' : ''"
          >
            {{ item }}
          </span>
        </div>

        <div
          v-if="answersTemp.type === 'character' && answersTemp.phrase"
          class="answer answer-item underline-right"
        >
          <span
            class="underline-tag"
            v-for="(item, index) in answersTemp.phrase.split('')"
            :key="`underline-tag-${index}`"
            :class="rightTemp.includes(index) ? 'selected' : ''"
          >
            {{ item }}
          </span>
        </div>
      </div>
    </div>

    <div v-if="this.questionType === 'Matching'" class="matching">
      <div class="answers md-layout md-gutter" style="padding-bottom: 12px">
        <div class="md-layout-item md-size-45 col-header">Đáp án</div>
        <div class="md-layout-item md-size-45 col-header">
          Câu trả lời của học sinh
        </div>
      </div>

      <div
        v-for="(item, index) in rightTemp"
        :key="index"
        class="md-layout md-gutter"
      >
        <div class="matching-fields md-layout-item md-size-45 answer-item">
          <img
            v-if="answersTemp.classificationFields[index].indexOf('http') === 0"
            style="width: 100px"
            :src="answersTemp.classificationFields[index]"
          />
          <span v-else>{{ answersTemp.classificationFields[index] }}</span>
          <span
            >&nbsp;&nbsp;&nbsp;&nbsp;&lt;===========&gt;&nbsp;&nbsp;&nbsp;&nbsp;</span
          >
          <img
            v-if="rightTemp[index].indexOf('http') === 0"
            style="width: 100px"
            :src="rightTemp[index]"
          />
          <span v-else>{{ rightTemp[index] }}</span>
        </div>

        <div
          v-if="studentAnswer"
          class="classification-fields md-layout-item md-size-35 answer-item"
          :style="
            studentAnswer.answer[index] == rightTemp[index]
              ? 'color: #28a745'
              : 'color: #c82333'
          "
        >
          {{ studentAnswer.answer[index] }}
        </div>
      </div>
    </div>

    <div v-if="this.questionType === 'WordSearch'" class="word-search">
      <div class="letter-table" style="padding: 0 24px 24px">
        <div class="md-layout md-gutter">
          <div class="md-layout-item md-size-70">
            <div
              v-if="
                answersTemp.w > 0 && answersTemp.h > 0 && answersTemp.crossword
              "
            >
              <div
                v-for="(col, colIdx) in Number(answersTemp.h)"
                :key="`crossword-col-${colIdx}`"
                class="crossword-row"
              >
                <span
                  v-for="(row, rowIdx) in Number(answersTemp.w)"
                  :key="`crossword-row-${rowIdx}`"
                >
                  <BaseInputQuestionText
                    v-model="answersTemp.crossword[colIdx][rowIdx]"
                    class="crossword-unit"
                    disabled
                    style="
                      border: 1px solid rgba(0, 0, 0, 0.1) !important;
                      width: 42px;
                      height: 42px !important;
                      border-radius: 0 !important;
                    "
                    customStyle="width: 42px; height: 42px !important; padding: 4px !important; border-radius: 0 !important; text-align: center"
                  />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="answers md-layout md-gutter">
        <div
          class="answer md-layout-item md-size-50 answer-item"
          v-for="(ans, index) in rightTemp"
          :key="index"
        >
          <BaseInputQuestionText
            v-model="rightTemp[index]"
            disabled
            @update="updateRightAnswerToParent"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import { mapGetters } from 'vuex'

export default {
  components: {},
  props: {
    answers: {
      type: [Array, Object],
      default: () => []
    },
    rightAnswer: {
      type: [Number, String, Array],
      default: () => ''
    },
    fakeChoices: {
      type: [Array, Object],
      default: () => []
    },
    questionType: {
      type: String,
      default: () => ''
    },
    studentAnswer: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    ...mapGetters(['eClass', 'config', 'googleDrive'])
  },
  data() {
    return {
      lodash: _,
      rightTemp: '',
      answersTemp: '',
      uploadLoading: {
        answersTemp: {},
        rightTemp: {}
      }
    }
  },
  watch: {
    rightAnswer: {
      handler() {
        this.rightTemp = _.cloneDeep(this.rightAnswer)
      },
      immediate: true,
      deep: true
    },
    answers: {
      handler() {
        this.answersTemp = _.cloneDeep(this.answers)
      },
      immediate: true,
      deep: true
    }
  },
  created() {},
  methods: {}
}
</script>

<style lang="scss" scoped>
.question-answer-preview {
  padding-top: 12px;
  .add-btn {
    display: flex;
    align-items: center;
    color: #1cab36;
    cursor: pointer;
    padding-bottom: 12px;

    .label {
      font-weight: bold;
    }

    .add-circle {
      padding: 0px;
      background: #1cab36;
      border-radius: 50%;
      margin-right: 8px;
      .md-icon {
        color: #fff;
      }
    }
  }

  .underline-right {
    flex-flow: wrap;
  }

  .answer-item {
    display: flex;
    align-items: center;
    padding-bottom: 14px;
    .md-radio {
      margin: 0px 16px 0px 0;
      display: flex;
      align-items: center;
    }

    .tag-list {
      margin-right: 20px;
    }

    .classification-tag {
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 5px;
      background: #fff;
      padding: 0 12px;
      height: 42px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      font-weight: bold;
      white-space: nowrap;
    }

    .underline-tag {
      cursor: pointer;
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 5px;
      background: #fff;
      padding: 0 12px;
      height: 34px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      white-space: nowrap;
      margin-right: 8px;
    }

    .underline-tag.selected {
      background: #1cab36;
      color: #fff;
    }
  }

  .matching {
    .col-header {
      padding-top: 12px;
      font-weight: bold;
    }
  }

  .word-search {
    .crossword-row {
      display: flex;
      justify-content: center;
      .crossword-unit {
        width: 50px;
        height: 50px;
      }
    }
  }
}
</style>
