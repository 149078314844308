<template>
  <div class="question-answer">
    <div v-if="this.questionType === 'MultiChoice'" class="multiple-choice">
      <div v-if="!preview" class="add-btn" @click="addAnswerItem">
        <span class="add-circle">
          <md-icon>add</md-icon>
        </span>
        <div class="label">Thêm câu trả lời</div>
      </div>

      <div class="answers md-layout md-gutter">
        <div
          class="answer md-layout-item md-size-50 answer-item"
          v-for="(ans, index) in answersTemp"
          :key="index"
        >
          <md-radio
            v-model="rightTemp"
            :value="index"
            :disabled="preview"
            @change="updateRightAnswerToParent"
          />
          <BaseInputQuestionRichText
            v-model="answersTemp[index]"
            :suffixIcon="preview ? '' : 'delete'"
            :disabled="preview"
            :label="label"
            @update="updateAnswersToParent"
            @clickSuffixIcon="deleteAnswerItem(index)"
          />
        </div>
      </div>
      <div
        v-if="studentAnswer"
        class="answer md-layout-item md-size-100 answer-item"
      >
        <strong>Câu trả lời của học sinh: </strong>
        <div
          style="padding-left: 15px"
          :style="
            studentAnswer.answer == rightTemp
              ? 'color: #28a745'
              : 'color: #c82333'
          "
          v-html="answersTemp[studentAnswer.answer]"
        ></div>
      </div>
    </div>

    <div v-if="this.questionType === 'ShortAnswer'" class="short-answer">
      <div v-if="!preview" class="add-btn" @click="addRightAnswerItem">
        <span class="add-circle">
          <md-icon>add</md-icon>
        </span>
        <div class="label">Thêm câu trả lời</div>
      </div>

      <div class="answers md-layout md-gutter">
        <div
          class="answer md-layout-item md-size-50 answer-item"
          v-for="(ans, index) in rightTemp"
          :key="index"
        >
          <BaseInputQuestionText
            v-model="rightTemp[index]"
            :suffixIcon="preview ? '' : 'delete'"
            :disabled="preview"
            @update="updateRightAnswerToParent"
            @clickSuffixIcon="deleteRightAnswerItem(index)"
          />
        </div>
      </div>
      <div
        v-if="studentAnswer"
        class="answer md-layout-item md-size-100 answer-item"
      >
        <strong>Câu trả lời của học sinh: </strong>
        <div
          style="padding-left: 15px"
          :style="
            studentAnswer.answer == rightTemp
              ? 'color: #28a745'
              : 'color: #c82333'
          "
          v-html="studentAnswer.answer"
        ></div>
      </div>
    </div>

    <div v-if="this.questionType === 'StringSorting'" class="string-sorting">
      <div class="answers">
        <div class="answer answer-item">
          <BaseInputQuestionText
            v-model="rightTemp"
            :disabled="preview"
            @update="updateRightAnswerToParent"
          />
        </div>

        <div class="note" style="padding-top: 14px; font-size: 12px">
          <span style="font-weight: bold">Chú ý: </span> Nhập vào một từ(không
          chứa dấu cách) hoặc một câu đáp án, EasySchool sẽ tự động trộn chữ
          cái(nếu là 1 từ) hoặc các từ (nếu là 1 câu). Học sinh sẽ phải sắp xếp
          các chữ cái, từ để tạo thành từ, câu đúng.
        </div>
      </div>
      <div
        v-if="studentAnswer"
        class="answer md-layout-item md-size-100 answer-item"
      >
        <strong>Câu trả lời của học sinh: </strong>
        <div
          style="padding-left: 15px"
          :style="
            studentAnswer.answer == rightTemp
              ? 'color: #28a745'
              : 'color: #c82333'
          "
          v-html="studentAnswer.answer"
        ></div>
      </div>
    </div>

    <div v-if="this.questionType === 'Speaking'" class="speaking">
      <div class="answers">
        <div class="answer answer-item">
          <BaseInputQuestionText
            v-model="rightTemp"
            :disabled="preview"
            @update="updateRightAnswerToParent"
          />
        </div>

        <div class="note" style="padding-top: 14px; font-size: 12px">
          <span style="font-weight: bold">Chú ý: </span> Nhập vào một từ hoặc
          câu để học sinh nói phát âm từ hoặc câu đó.
        </div>
      </div>
      <div
        v-if="studentAnswer"
        class="answer md-layout-item md-size-100 answer-item"
      >
        <strong>Câu trả lời của học sinh: </strong>
        <div
          style="padding-left: 15px"
          :style="
            studentAnswer.answer == rightTemp
              ? 'color: #28a745'
              : 'color: #c82333'
          "
          v-html="studentAnswer.answer"
        ></div>
      </div>
    </div>

    <div
      v-if="this.questionType === 'CompleteSentence'"
      class="complete-sentence"
    >
      <div class="answers">
        <div v-if="!preview" style="width: 100%">
          <BaseInputQuestionText
            v-model="answersTemp.phrase"
            label="Nhập vào câu cần hoàn thành"
            style="margin-bottom: 12px"
          />
        </div>
        <div class="answer answer-item">
          <div class="tag-list">
            <template
              v-for="(item, index) in splitIntoWords(answersTemp?.phrase)"
            >
              <div :key="index">
                <template v-if="rightTemp.includes(index)">
                  <el-tooltip placement="top" effect="light">
                    <template #content>
                      <span
                        class="complete-sentence-tag"
                        style="
                          background-color: #1cab36;
                          color: white;
                          margin-right: 5px;
                        "
                        >{{ item }}</span
                      >
                      <span
                        :key="index"
                        class="complete-sentence-tag"
                        style="
                          background-color: #ff5252;
                          color: white;
                          margin-right: 5px;
                        "
                      >
                        {{ fakeChoicesTemp[index] }}
                      </span>
                    </template>
                    <div
                      class="classification-tag"
                      style="
                        background-color: #ff8e1d;
                        color: white;
                        cursor: pointer;
                      "
                      @click="
                        !preview
                          ? handleSelectCompleteSentenceItem(index, item)
                          : null
                      "
                    >
                      <svg-icon icon-class="question-circle" />
                    </div>
                  </el-tooltip>
                </template>
                <template v-else>
                  <span
                    class="classification-tag"
                    style="margin-right: 5px"
                    @click="
                      !preview
                        ? handleSelectCompleteSentenceItem(index, item)
                        : null
                    "
                    >{{ item }}</span
                  >
                </template>
              </div>
            </template>
          </div>
        </div>

        <div
          v-if="!preview"
          class="note"
          style="padding-top: 14px; font-size: 12px"
        >
          <span style="font-weight: bold">Chú ý: </span>Chọn từ và nhập một đáp
          án sai để học sinh lựa chọn giữa 2 đáp án là từ bạn chọn và đáp án sai
          bạn nhập.
        </div>
      </div>
      <div
        v-if="studentAnswer"
        class="answer md-layout-item md-size-100 answer-item"
      >
        <strong>Câu trả lời của học sinh: </strong>
        <div
          style="padding-left: 15px"
          :style="
            studentAnswer.answer == answersTemp.phrase.split(' ')[rightTemp[0]]
              ? 'color: #28a745'
              : 'color: #c82333'
          "
          v-html="studentAnswer.answer"
        ></div>
      </div>
    </div>

    <div v-if="this.questionType === 'Classification'" class="classification">
      <div v-if="!preview" class="add-btn">
        <span class="add-circle">
          <md-icon>add</md-icon>
        </span>
        <div class="label">Thêm trường</div>
      </div>

      <div class="answers md-layout md-gutter">
        <div class="classification-fields md-layout-item answer-item">
          <div
            v-if="preview"
            style="
              margin-left: 20px;
              padding-left: 16px;
              padding-right: 16px;
              cursor: not-allowed;
              background: rgba(0, 0, 0, 0.05);
              display: flex;
              flex-wrap: wrap;
              width: 100%;
              padding-top: 4px;
              padding-bottom: 4px;
            "
          >
            <el-tag
              type="info"
              v-for="item in answersTemp.classificationFields"
              style="margin-right: 8px"
              :key="isNil(item[select1]) ? item : item[select1]"
              v-html="item"
            ></el-tag>
          </div>
          <BaseInputQuestionSelect
            key="select1"
            v-model="answersTemp.classificationFields"
            :multiple="true"
            :allowCreate="true"
            :filterable="true"
            :createTag="true"
            :disabled="preview"
            v-else
            style="width: 100%"
            @update="handleUpdateClassificationAnswer('classificationFields')"
          />
        </div>
      </div>

      <div v-if="!preview" class="add-btn">
        <span class="add-circle">
          <md-icon>add</md-icon>
        </span>
        <div class="label">Thêm đáp án</div>
      </div>

      <div class="answers md-layout md-gutter">
        <div class="classification-fields md-layout-item answer-item">
          <div
            v-if="preview"
            style="
              margin-left: 20px;
              padding-left: 16px;
              padding-right: 16px;
              cursor: not-allowed;
              background: rgba(0, 0, 0, 0.05);
              display: flex;
              flex-wrap: wrap;
              width: 100%;
              padding-top: 4px;
              padding-bottom: 4px;
            "
          >
            <el-tag
              type="info"
              v-for="item in answersTemp.list"
              style="margin-right: 8px"
              :key="isNil(item[select2]) ? item : item[select2]"
              v-html="item"
            ></el-tag>
          </div>
          <BaseInputQuestionSelect
            key="select2"
            v-model="answersTemp.list"
            :multiple="true"
            :allowCreate="true"
            :filterable="true"
            :createTag="true"
            :disabled="preview"
            v-else
            style="width: 100%"
            @update="handleUpdateClassificationAnswer('classificationList')"
          />
        </div>
      </div>

      <div v-if="!preview" class="add-btn">
        <span class="add-circle">
          <md-icon>add</md-icon>
        </span>
        <div class="label">Đáp án đúng</div>
      </div>

      <div class="answers">
        <div
          class="classification-fields answer-item"
          v-for="(item, index) in answersTemp.classificationFields"
          :key="`classification-item-${index}`"
        >
          <span class="tag-list"
            ><span class="classification-tag" v-html="item"></span
          ></span>
          <div
            v-if="preview"
            style="
              margin-left: 20px;
              padding-left: 16px;
              padding-right: 16px;
              cursor: not-allowed;
              background: rgba(0, 0, 0, 0.05);
              display: flex;
              flex-wrap: wrap;
              width: 100%;
              padding-top: 4px;
              padding-bottom: 4px;
            "
          >
            <el-tag
              type="info"
              v-for="item in answersTemp.list
                ? answersTemp.list.map((ans, index) => {
                    return { _id: index, name: ans }
                  })
                : []"
              style="margin-right: 8px"
              :key="item._id"
              v-html="item.name"
            ></el-tag>
          </div>
          <BaseInputQuestionSelect
            v-model="rightTemp[index]"
            :multiple="true"
            :allowCreate="false"
            :filterable="true"
            :createTag="true"
            :disabled="preview"
            v-else
            :options="
              answersTemp.list
                ? answersTemp.list.map((ans, index) => {
                    return { _id: index, name: ans }
                  })
                : []
            "
            style="width: 100%"
            @update="handleUpdateClassificationAnswer('classificationRight')"
          />
        </div>
      </div>
      <div
        v-if="studentAnswer"
        class="answer md-layout-item md-size-100 answer-item"
      >
        <strong>Câu trả lời của học sinh: </strong>
        <div
          style="padding-left: 15px"
          :style="
            studentAnswer.answer == rightTemp
              ? 'color: #28a745'
              : 'color: #c82333'
          "
          v-html="studentAnswer.answer"
        ></div>
      </div>
    </div>

    <div v-if="this.questionType === 'UnderlineWord'" class="underline-word">
      <div class="answers">
        <div
          class="answer answer-item"
          style="display: flex; align-items: center"
        >
          <BaseInputQuestionSelect
            key="select2"
            v-model="answersTemp.type"
            :disabled="preview"
            :options="[
              {
                _id: 'word',
                name: 'Từ'
              },
              {
                _id: 'character',
                name: 'Chữ cái'
              }
            ]"
            label="Dạng gạch chân"
            @update="handleUpdateUnderlineWordAnswer('underlineType')"
            style="padding-right: 24px"
          />

          <BaseInputQuestionText
            v-model="answersTemp.phrase"
            :disabled="preview"
            label="Nhập vào từ hoặc cụm từ"
            @update="handleUpdateUnderlineWordAnswer('underlinePhrase')"
          />
        </div>

        <div
          v-if="answersTemp.type === 'word' && answersTemp.phrase"
          class="answer answer-item underline-right"
        >
          <span
            class="underline-tag"
            v-for="(item, index) in answersTemp.phrase.split(' ')"
            :key="`underline-tag-${index}`"
            :class="rightTemp.includes(index) ? 'selected' : ''"
            @click="handleSelectUnderlineWordTag(index)"
          >
            {{ item }}
          </span>
        </div>

        <div
          v-if="answersTemp.type === 'character' && answersTemp.phrase"
          class="answer answer-item underline-right"
        >
          <span
            class="underline-tag"
            v-for="(item, index) in answersTemp.phrase.split('')"
            :key="`underline-tag-${index}`"
            :class="rightTemp.includes(index) ? 'selected' : ''"
            @click="handleSelectUnderlineWordTag(index)"
          >
            {{ item }}
          </span>
        </div>
      </div>
      <div
        v-if="studentAnswer"
        class="answer md-layout-item md-size-100 answer-item"
      >
        <strong>Câu trả lời của học sinh: </strong>
        <div
          style="padding-left: 15px"
          :style="
            studentAnswer.answer == rightTemp
              ? 'color: #28a745'
              : 'color: #c82333'
          "
          v-html="studentAnswer.answer"
        ></div>
      </div>
    </div>

    <div v-if="this.questionType === 'Matching'" class="matching">
      <div v-if="!preview" class="add-btn" @click="handleAddMatchingAnswerItem">
        <span class="add-circle">
          <md-icon>add</md-icon>
        </span>
        <div class="label">Thêm đáp án</div>
      </div>

      <div class="answers md-layout md-gutter" style="padding-bottom: 12px">
        <div class="md-layout-item md-size-40 col-header">Cột A</div>
        <div class="md-layout-item md-size-40 col-header">Cột B</div>
      </div>

      <div
        v-for="(item, index) in rightTemp"
        :key="index"
        class="md-layout md-gutter"
      >
        <div class="matching-fields md-layout-item md-size-45 answer-item">
          <img
            v-if="preview && isImgUrl(answersTemp.classificationFields[index])"
            :src="answersTemp.classificationFields[index] | media"
          />
          <BaseInputQuestionFile
            accept="image/*"
            :labelSize="16"
            :disabled="preview"
            v-model="answersTemp.classificationFields[index]"
            iconClass="image"
            :storageClass="config.storageClass"
            :loading.sync="uploadLoading.answersTemp[index.toString()]"
            style="width: 100%"
            v-else
            @file-selected="
              (val) => {
                handleUpload('answersTemp', 'classificationFields', index, val)
              }
            "
            @clear="clearFile('answersTemp', 'classificationFields', index)"
            @update="updateAnswersToParent"
          />
        </div>

        <div
          class="classification-fields md-layout-item md-size-45 answer-item"
        >
          <img
            v-if="preview && isImgUrl(rightTemp[index])"
            :src="rightTemp[index] | media"
          />
          <BaseInputQuestionFile
            accept="image/*"
            :labelSize="16"
            :disabled="preview"
            v-model="rightTemp[index]"
            iconClass="image"
            style="width: 100%"
            :storageClass="config.storageClass"
            :loading.sync="uploadLoading.rightTemp[index.toString()]"
            v-else
            @file-selected="
              (val) => {
                handleUpload('rightTemp', '', index, val)
              }
            "
            @clear="clearFile('rightTemp', '', index)"
            @update="updateRightAnswerToParent"
          />
        </div>

        <div
          v-if="!preview"
          class="classification-fields md-layout-item md-size-10 answer-item"
          style="text-align: right"
        >
          <span
            @click="handleRemoveMatchingAnswerItem(index)"
            style="padding: 4px"
          >
            <md-icon>delete</md-icon>
          </span>
        </div>
      </div>

      <div class="note" style="padding-top: 32px; font-size: 12px">
        <span style="font-weight: bold">Chú ý: </span> Nhập vào đáp án đúng nối
        tương ứng với hàng ở cột A và B. Khi học sinh làm bài EasySchool sẽ tự
        động trộn đáp án ở cột A và B cho học sinh làm bài.
      </div>
      <div
        v-if="studentAnswer"
        class="answer md-layout-item md-size-100 answer-item"
      >
        <strong>Câu trả lời của học sinh: </strong>
        <div
          style="padding-left: 15px"
          :style="
            studentAnswer.answer == rightTemp
              ? 'color: #28a745'
              : 'color: #c82333'
          "
          v-html="studentAnswer.answer"
        ></div>
      </div>
    </div>

    <div v-if="this.questionType === 'WordSearch'" class="word-search">
      <div class="letter-table" style="padding: 0 24px 24px">
        <div class="md-layout md-gutter">
          <div class="md-layout-item md-size-30">
            <BaseInputQuestionText
              v-model="answersTemp.w"
              :disabled="preview"
              label="Số hàng"
              type="number"
              style="padding-bottom: 12px"
              @update="handleChangeCrosswordSize"
            />

            <BaseInputQuestionText
              v-model="answersTemp.h"
              :disabled="preview"
              label="Số cột"
              type="number"
              @update="handleChangeCrosswordSize"
            />
          </div>
          <div class="md-layout-item md-size-70">
            <div
              v-if="
                answersTemp.w > 0 && answersTemp.h > 0 && answersTemp.crossword
              "
            >
              <div
                v-for="(col, colIdx) in Number(answersTemp.h)"
                :key="`crossword-col-${colIdx}`"
                class="crossword-row"
              >
                <span
                  v-for="(row, rowIdx) in Number(answersTemp.w)"
                  :key="`crossword-row-${rowIdx}`"
                >
                  <BaseInputQuestionText
                    v-model="answersTemp.crossword[colIdx][rowIdx]"
                    class="crossword-unit"
                    :disabled="preview"
                    style="
                      border: 1px solid rgba(0, 0, 0, 0.1) !important;
                      width: 42px;
                      height: 42px !important;
                      border-radius: 0 !important;
                    "
                    customStyle="width: 42px; height: 42px !important; padding: 4px !important; border-radius: 0 !important; text-align: center"
                    @update="updateAnswersToParent"
                  />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="!preview" class="add-btn" @click="addRightAnswerItem">
        <span class="add-circle">
          <md-icon>add</md-icon>
        </span>
        <div class="label">Thêm câu đáp án đúng</div>
      </div>

      <div class="answers md-layout md-gutter">
        <div
          class="answer md-layout-item md-size-50 answer-item"
          v-for="(ans, index) in rightTemp"
          :key="index"
        >
          <BaseInputQuestionText
            v-model="rightTemp[index]"
            :suffixIcon="preview ? '' : 'delete'"
            :disabled="preview"
            @update="updateRightAnswerToParent"
            @clickSuffixIcon="deleteRightAnswerItem(index)"
          />
        </div>
      </div>
      <div
        v-if="studentAnswer"
        class="answer md-layout-item md-size-100 answer-item"
      >
        <strong>Câu trả lời của học sinh: </strong>
        <div
          style="padding-left: 15px"
          :style="
            studentAnswer.answer == rightTemp
              ? 'color: #28a745'
              : 'color: #c82333'
          "
          v-html="studentAnswer.answer"
        ></div>
      </div>
    </div>

    <el-dialog
      title="Nhập đán án sai"
      :visible.sync="completeSentenceDialog"
      width="30%"
      append-to-body
      center
    >
      <div class="dialog-content" v-if="questionType === 'CompleteSentence'">
        <BaseInputQuestionText
          v-model="fakeChoicesTemp[rightTemp[0]]"
          label="Nhập đáp án sai"
        />

        <div style="text-align: right; padding-top: 12px">
          <el-button type="primary" @click="handleSaveFakeChoice"
            >Xác nhận</el-button
          >
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import _ from 'lodash'
import { uploadHomeworkAttachment } from '@/api/content'
import { mapGetters } from 'vuex'

export default {
  components: {},
  props: {
    answers: {
      type: [Array, Object],
      default: () => []
    },
    rightAnswer: {
      type: [Number, String, Array],
      default: () => ''
    },
    fakeChoices: {
      type: Object,
      default: () => {}
    },
    questionType: {
      type: String,
      default: () => ''
    },
    preview: {
      type: Boolean,
      default: false
    },
    studentAnswer: {
      type: Object,
      default: () => {}
    },
    label: {
      type: String,
      default: () => ''
    }
  },
  computed: {
    ...mapGetters(['eClass', 'config', 'googleDrive'])
  },
  data() {
    return {
      rightTemp: '',
      answersTemp: '',
      fakeChoicesTemp: {},
      uploadLoading: {
        answersTemp: {},
        rightTemp: {}
      },
      completeSentenceDialog: false
    }
  },
  watch: {
    rightAnswer: {
      handler() {
        this.rightTemp = _.cloneDeep(this.rightAnswer)
      },
      immediate: true,
      deep: true
    },
    answers: {
      handler(val) {
        this.answersTemp = _.cloneDeep(val)
      },
      immediate: true,
      deep: true
    },
    fakeChoices: {
      handler(val) {
        this.fakeChoicesTemp = _.cloneDeep(val)
      },
      immediate: true,
      deep: true
    }
  },
  created() {},
  methods: {
    isNil(val) {
      return _.isNil(val)
    },
    isImgUrl(value) {
      if (value.endsWith('&ggdrive')) {
        return true
      }

      const lowerValue = value.toLowerCase()
      return (
        lowerValue.endsWith('.png') ||
        lowerValue.endsWith('.jpg') ||
        lowerValue.endsWith('.jpeg') ||
        lowerValue.endsWith('.tiff') ||
        lowerValue.endsWith('.gif')
      )
    },
    splitIntoWords(phrase) {
      return phrase?.split(' ') || []
    },
    handleSaveFakeChoice() {
      if (
        !this.fakeChoicesTemp[this.rightTemp[0]] ||
        this.fakeChoicesTemp[this.rightTemp[0]] === ''
      ) {
        this.$notify({
          type: 'warning',
          title: 'Lỗi',
          message: 'Vui lòng nhập đáp án sai.'
        })
        return
      }

      this.updateAnswersToParent()
      this.updateRightAnswerToParent()
      this.updateFakeChoiceToParent()

      this.completeSentenceDialog = false
    },

    handleSelectCompleteSentenceItem(index, item) {
      if (this.preview) {
        return
      }

      const fakeChoice =
        index === this.rightTemp[0] ? this.fakeChoicesTemp[index] : ''
      this.rightTemp = [index]
      this.fakeChoicesTemp = {
        [index]: fakeChoice
      }
      this.completeSentenceDialog = true
    },

    handleChangeCrosswordSize() {
      this.resetCrossword()
      this.updateAnswersToParent()
    },

    resetCrossword() {
      this.answersTemp.crossword = []
      for (let i = 0; i < this.answersTemp.h; i++) {
        this.answersTemp.crossword.push([])
        for (let j = 0; j < this.answersTemp.w; j++) {
          this.answersTemp.crossword[i].push('')
        }
      }
    },

    handleRemoveMatchingAnswerItem(index) {
      if (
        this.answersTemp &&
        this.answersTemp.classificationFields &&
        index < this.rightTemp.length
      ) {
        this.answersTemp.classificationFields.splice(index, 1)
        this.rightTemp.splice(index, 1)

        this.updateAnswersToParent()
        this.updateRightAnswerToParent()
      }
    },

    handleAddMatchingAnswerItem() {
      if (this.answersTemp && this.answersTemp.classificationFields) {
        this.answersTemp.classificationFields.push('')
        this.rightTemp.push('')

        this.updateAnswersToParent()
        this.updateRightAnswerToParent()
      }
    },

    handleSelectUnderlineWordTag(rightIndex) {
      const index = _.findIndex(this.rightTemp, (v) => {
        return v === rightIndex
      })

      if (index !== -1) {
        this.rightTemp.splice(index, 1)
      } else {
        this.rightTemp.push(rightIndex)
        this.rightTemp = this.rightTemp.sort()
      }

      this.updateRightAnswerToParent()
    },

    handleUpdateUnderlineWordAnswer(field) {
      if (['underlineType', 'underlinePhrase'].includes(field)) {
        this.rightTemp = []
      }

      this.updateAnswersToParent()
      this.updateRightAnswerToParent()
    },

    handleUpdateClassificationAnswer(field) {
      if (['classificationFields', 'classificationList'].includes(field)) {
        this.rightTemp = []
      }

      this.updateAnswersToParent()
      this.updateRightAnswerToParent()
    },

    deleteAnswerItem(index) {
      this.answersTemp.splice(index, 1)
      this.rightTemp = ''
      this.updateAnswersToParent()
      this.updateRightAnswerToParent()
    },

    deleteRightAnswerItem(index) {
      this.rightTemp.splice(index, 1)
      this.updateRightAnswerToParent()
    },

    addAnswerItem() {
      this.answersTemp.push('')
      this.updateAnswersToParent()
    },

    addRightAnswerItem() {
      this.rightTemp.push('')
      this.updateRightAnswerToParent()
    },

    updateAnswersToParent() {
      this.$emit('updateAnswer', this.questionType, this.answersTemp)
    },

    updateFakeChoiceToParent() {
      this.$emit(
        'updateFakeChoiceToParent',
        this.questionType,
        this.fakeChoicesTemp
      )
    },

    updateRightAnswerToParent() {
      this.$emit('updateRightAnswer', this.questionType, this.rightTemp)
    },

    async handleUpload(field, key, index, files) {
      if (this.config.storageClass === 1) {
        await this.$store.dispatch('media/GetSharedLink', {
          fileId: files.id,
          accessToken: this.googleDrive.accessToken
        })
        const fileId = files.id + '&ggdrive'
        if (field === 'answersTemp') {
          this.$set(this[field][key], index, fileId)
        } else {
          this.$set(this[field], index, fileId)
        }
        this.updateAnswersToParent()
        this.updateRightAnswerToParent()
        return
      }

      if (files.length > 0) {
        await this.uploadAttachment(files[0], field, key, index)
      }
    },

    async uploadAttachment(file, field, key, index) {
      if (this.uploadLoading[field][key.toString()]) {
        this.$notify({
          type: 'warning',
          title: 'Lỗi',
          message: 'Vui lòng đợi tải lên các tập tin trước đó hoàn tất.'
        })

        return
      }

      if (!this.eClass) {
        this.$notify({
          type: 'warning',
          title: 'Sai thông tin lớp học. Vui lòng thử lại sau giây lát'
        })
        return
      }

      this.$set(this.uploadLoading[field], index.toString(), true)

      try {
        const response = await uploadHomeworkAttachment({
          file,
          classId: this.eClass._id
        })

        if (field === 'answersTemp') {
          this.$set(this[field][key], index, '/' + response.data.key)
        } else {
          this.$set(this[field], index, '/' + response.data.key)
        }

        this.updateAnswersToParent()
        this.updateRightAnswerToParent()
      } catch (error) {
        // console.log(error)
      }

      this.$set(this.uploadLoading[field], index.toString(), false)
    },

    clearFile(field, key, index) {
      if (field === 'answersTemp') {
        this.$set(this[field][key], index, null)
      } else {
        this.$set(this[field], index, null)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.question-answer {
  padding-top: 12px;
  .add-btn {
    display: flex;
    align-items: center;
    color: #1cab36;
    cursor: pointer;
    padding-bottom: 12px;

    .label {
      font-weight: bold;
    }

    .add-circle {
      padding: 0px;
      background: #1cab36;
      border-radius: 50%;
      margin-right: 8px;
      .md-icon {
        color: #fff;
      }
    }
  }

  .answer-item {
    display: flex;
    align-items: center;
    padding-bottom: 14px;
    .md-radio {
      margin: 0px 16px 0px 0;
      display: flex;
      align-items: center;
    }

    .tag-list {
      margin-right: 20px;
    }

    .classification-tag {
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 5px;
      background: #fff;
      padding: 0 12px;
      height: 42px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      font-weight: bold;
      white-space: nowrap;
    }

    .underline-tag {
      cursor: pointer;
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 5px;
      background: #fff;
      padding: 0 12px;
      height: 34px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      white-space: nowrap;
      margin-right: 8px;
    }

    .underline-tag.selected {
      background: #1cab36;
      color: #fff;
    }
  }

  .matching {
    .col-header {
      padding-top: 12px;
      font-weight: bold;
    }
  }

  .word-search {
    .crossword-row {
      display: flex;
      justify-content: center;
      .crossword-unit {
        width: 50px;
        height: 50px;
      }
    }
  }

  .complete-sentence {
    .tag-list {
      display: flex;
      flex-wrap: wrap;

      .classification-tag {
        margin-right: 5px;
        margin-top: 5px;
        cursor: pointer;
      }
    }

    .list-answer {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
    }

    .list-answer-item {
      display: flex;
      flex-wrap: wrap;
      padding-top: 12px;

      .classification-tag {
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        background: #fff;
        padding: 0 12px;
        height: 42px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        font-weight: bold;
        white-space: nowrap;
        cursor: pointer;
      }
    }
  }
}

.complete-sentence-tag {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  background: #fff;
  padding: 0 12px;
  height: 42px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  white-space: nowrap;
}
</style>
