import request from '@/utils/request'
import axios from 'axios'
import serialize from '@/utils/serialize'

const CancelToken = axios.CancelToken

export function getSchoolSubmissionStatisWeek(data) {
  return request({
    url: '/school/submission-in-days',
    method: 'post',
    data
  })
}

export function getTeacherSchoolStatisMonth(data) {
  return request({
    url: '/school/submission-in-weeks',
    method: 'post',
    data
  })
}

export function getTeacherSubmissionStatisWeek(data) {
  return request({
    url: '/teacher/submission-in-days',
    method: 'post',
    data
  })
}

export function getTeacherSubmissionStatisMonth(data) {
  return request({
    url: '/teacher/submission-in-weeks',
    method: 'post',
    data
  })
}

export function getTeacherSubmissionStatisClassViewWeek(data) {
  return request({
    url: '/class/submission-in-days',
    method: 'post',
    data
  })
}

export function getTeacherSubmissionStatisClassViewMonth(data) {
  return request({
    url: '/class/submission-in-weeks',
    method: 'post',
    data
  })
}

export function getSchoolConfig() {
  return request({
    url: '/config',
    method: 'get'
  })
}

export function updateSchoolConfig(data) {
  return request({
    url: '/config',
    method: 'put',
    data
  })
}

export function getClasses(data) {
  return request({
    url: '/class' + serialize(data),
    method: 'get'
  })
}

export function getClassesWithStatistics(data) {
  return request({
    url: '/class/list-with-statistics' + serialize(data),
    method: 'get'
  })
}

export function getClassesBySchool(data) {
  return request({
    url: '/statistics/list-class-by-school' + serialize(data),
    method: 'get'
  })
}

export function getClassOverallStatistics(data) {
  return request({
    url: '/statistics/class-overall-statistics',
    method: 'post',
    data
  })
}

export function getArenaStatistics(data) {
  return request({
    url: '/statistics/easy-arena',
    method: 'post',
    data
  })
}

export function getPlatformOverallStatistics(data) {
  return request({
    url: '/statistics/platform-overall-statistics',
    method: 'post',
    data
  })
}

export function getValidContestClasses(data) {
  return request({
    url: '/class/getValidContestClasses' + serialize(data),
    method: 'get'
  })
}

export function getClass(data) {
  return request({
    url: '/class/detail' + serialize(data),
    method: 'get'
  })
}

export function approveAllStudents(data) {
  return request({
    url: '/class/approve-all-students' + serialize(data),
    method: 'post'
  })
}

export function createClass(data) {
  return request({
    url: '/class',
    method: 'post',
    data
  })
}

export function updateClass(data) {
  return request({
    url: '/class' + serialize({ classId: data.classId }),
    method: 'put',
    data
  })
}

export function deleteClass(data) {
  return request({
    url: '/class' + serialize({ classId: data._id }),
    method: 'delete'
  })
}

export function getHomeworks(query) {
  return request({
    url: '/homework' + serialize(query),
    method: 'get'
  })
}

export function getHomework(query) {
  return request({
    url: '/homework/detail' + serialize(query),
    method: 'get'
  })
}

export function createHomeworkComment(data) {
  return request({
    url: '/homework/comments',
    method: 'post',
    data
  })
}

export function getHomeworkComment(data) {
  return request({
    url: '/homework/comments' + serialize({ submissionId: data.submissionId }),
    method: 'get'
  })
}

export function updateHomeworkComment(data) {
  return request({
    url: '/homework/comments',
    method: 'put',
    data
  })
}

export function deleteHomeworkComment(data) {
  return request({
    url: '/homework/comments',
    method: 'delete',
    data
  })
}

export function createHomeworkCommentUploadUrl(data) {
  if (!data.file) {
    throw new Error('E_FILE_INVALID')
  }

  const body = {
    filename: data.file.name,
    contentType: data.file.type,
    fileSize: data.file.size
  }

  return request({
    url: '/homework/comments/create-upload-url' + serialize({ submissionId: data.submissionId }),
    method: 'post',
    data: body
  })
}

export function createQuestionHomework(data) {
  return request({
    url: '/homework/es-homework' + serialize({ classId: data.classId }),
    method: 'post',
    data
  })
}

export function pushQGQuestionHomework(data) {
  return request({
    url: '/homework/es-homework-push-qgs',
    method: 'put',
    data
  })
}

export function pullQGQuestionHomework(data) {
  return request({
    url: '/homework/es-homework-pull-qgs',
    method: 'put',
    data
  })
}

export function updateQuestionHomework(data) {
  return request({
    url: '/homework/es-homework' + serialize({ classId: data.classId }),
    method: 'put',
    data
  })
}

export function publishQuestionHomework(data) {
  return request({
    url: '/homework/es-homework/publish' + serialize({ classId: data.classId }),
    method: 'put',
    data
  })
}

export function createFileHomework(data) {
  return request({
    url: '/homework/file' + serialize({ classId: data.classId }),
    method: 'post',
    data
  })
}

export function updateFileHomework(data) {
  return request({
    url: '/homework/file' + serialize({ homeworkId: data._id }),
    method: 'put',
    data
  })
}

export function createAttachmentHomework(data) {
  return request({
    url: '/homework/attachment' + serialize({ classId: data.classId }),
    method: 'post',
    data
  })
}

export function updateAttachmentHomework(data) {
  return request({
    url: '/homework/attachment' + serialize({ homeworkId: data._id }),
    method: 'put',
    data
  })
}

export function createDocumentHomework(data) {
  return request({
    url: '/homework/document' + serialize({ classId: data.classId }),
    method: 'post',
    data
  })
}

export function updateDocumentHomework(data) {
  return request({
    url: '/homework/document' + serialize({ homeworkId: data._id }),
    method: 'put',
    data
  })
}

export function createMpHomework(data) {
  return request({
    url: '/homework/mp' + serialize({ classId: data.classId, appId: data.appId }),
    method: 'post',
    data
  })
}

export function updateMpHomework(data) {
  return request({
    url: '/homework/mp',
    method: 'put',
    data
  })
}

export function createEasyClassHomework(data) {
  return request({
    url: '/homework/easy-class' + serialize({ classId: data.classId }),
    method: 'post',
    data
  })
}

export function updateEasyClassHomework(data) {
  return request({
    url: '/homework/easy-class',
    method: 'put',
    data
  })
}

export function createUnistartHomework(data) {
  return request({
    url: '/homework/unistart' + serialize({ classId: data.classId }),
    method: 'post',
    data
  })
}

export function updateUnistartHomework(data) {
  return request({
    url: '/homework/unistart',
    method: 'put',
    data
  })
}

export function createEasyForKidsHomework(data) {
  return request({
    url: '/homework/easy-for-kids' + serialize({ classId: data.classId }),
    method: 'post',
    data
  })
}

export function updateEasyForKidsHomework(data) {
  return request({
    url: '/homework/easy-for-kids',
    method: 'put',
    data
  })
}

export function createEasyCertificateHomework(data) {
  return request({
    url: '/homework/easy-certificate' + serialize({ classId: data.classId }),
    method: 'post',
    data
  })
}

export function updateEasyCertificateHomework(data) {
  return request({
    url: '/homework/easy-certificate',
    method: 'put',
    data
  })
}
export function uploadHomeworkAttachment(data, handleProgress, cancel) {
  const upload = new FormData()
  upload.append('file', data.file)

  return request({
    url: '/homework/upload' + serialize({ classId: data.classId, name: data.file.name, size: data.file.size }),
    method: 'put',
    timeout: false,
    data: upload,
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    onUploadProgress: handleProgress,
    cancelToken: new CancelToken(function executor(c) {
      if (cancel) {
        cancel(c)
      }
    })
  })
}

export function getHomeworkSubmission(query) {
  return request({
    url: '/homework/submission' + serialize(query),
    method: 'get'
  })
}

export function getMissionTemplate(query) {
  return request({
    url: '/mentor/mission-templates' + serialize(query),
    method: 'get'
  })
}

export function getMission(query) {
  return request({
    url: '/mentor/missions' + serialize(query),
    method: 'get'
  })
}

export function getTopUseTemp(query) {
  return request({
    url: '/mentor/missions/top-use-temp',
    method: 'get'
  })
}

export function getTopCompletedGroup(query) {
  return request({
    url: '/mentor/missions/top-completed-group',
    method: 'get'
  })
}

export function getSortEMission(query) {
  return request({
    url: '/mentor/missions/sort-expiredAt' + serialize(query),
    method: 'get'
  })
}

export function getSortAMission(query) {
  return request({
    url: '/mentor/missions/sort-alphabet' + serialize(query),
    method: 'get'
  })
}

export function creatMission(data) {
  return request({
    url: '/mentor/missions/new-mission',
    method: 'post',
    data
  })
}

export function updateMission(data) {
  return request({
    url: '/mentor/missions/update-mission',
    method: 'post',
    data
  })
}

export function getBugDef(query) {
  return request({
    url: '/mentor/bugs/bugs-def',
    method: 'get'
  })
}

export function getIdeaDef(query) {
  return request({
    url: '/mentor/ideas/ideas-def',
    method: 'get'
  })
}

export function getStudentMission(query) {
  return request({
    url: '/mentor/missions/students' + serialize(query),
    method: 'get'
  })
}

export function getStudentMissionSuccess(query) {
  return request({
    url: '/mentor/missions/students-success' + serialize(query),
    method: 'get'
  })
}

export function getStudentMissionNotSuccess(query) {
  return request({
    url: '/mentor/missions/students-not-success' + serialize(query),
    method: 'get'
  })
}

export function getStudentMissionStatistic(query) {
  return request({
    url: '/mentor/missions/student-statistics' + serialize(query),
    method: 'get'
  })
}

export function addStudent(data) {
  return request({
    url: '/student/add',
    method: 'post',
    data
  })
}

export function getStudents(data) {
  return request({
    url: '/student' + serialize(data),
    method: 'get'
  })
}

export function addExpiredAt(data) {
  return request({
    url: '/class/set-expired',
    method: 'post',
    data
  })
}

export function getStudentRanks(data) {
  return request({
    url: '/class/getStudentRank' + serialize(data),
    method: 'get'
  })
}

export function getSchoolStudents(data) {
  return request({
    url: '/student/school' + serialize(data),
    method: 'get'
  })
}

export function getStudent(data) {
  return request({
    url: '/student/detail' + serialize(data),
    method: 'get'
  })
}

export function approveJoinClass(data) {
  return request({
    url: '/student/approve-join-class' + serialize({
      studentId: data._id,
      classId: data.classId
    }),
    method: 'post',
    data: {
      status: data.status
    }
  })
}

export function disableStudentInClass(data) {
  return request({
    url: '/student/disable-student-in-class' + serialize({
      studentId: data._id,
      classId: data.classId
    }),
    method: 'post',
    data: {
      status: data.status
    }
  })
}

export function updateStudentInfo(data) {
  return request({
    url: '/student/update-info' + serialize({
      studentId: data._id,
      classId: data.classId
    }),
    method: 'post',
    data: {
      fullname: data.fullname
    }
  })
}

export function subscribeStudent(data) {
  return request({
    url: '/student/subscribe' + serialize(data),
    method: 'post'
  })
}

export function getSubscribeStudent(data) {
  return request({
    url: '/student/subscribe-list',
    method: 'post',
    data
  })
}

export function unsubscribeStudent(data) {
  return request({
    url: '/student/unsubscribe' + serialize(data),
    method: 'post'
  })
}

export function getRepresentativeStudents(data) {
  return request({
    url: '/student/representatives',
    method: 'post',
    data
  })
}

export function getRepresentativeTeachers(data) {
  return request({
    url: '/teacher/representatives',
    method: 'post',
    data
  })
}

export function getHardworkingClasses(data) {
  return request({
    url: '/class/hard-working-classes',
    method: 'post',
    data
  })
}

export function getSchool() {
  return request({
    url: '/school/detail',
    method: 'get'
  })
}

export function getGroup() {
  return request({
    url: '/group/detail',
    method: 'get'
  })
}

export function updateGroup(data) {
  return request({
    url: '/group',
    method: 'put',
    data
  })
}

export function getDepartment() {
  return request({
    url: '/department',
    method: 'get'
  })
}

export function createDepartment(data) {
  return request({
    url: '/department',
    method: 'post',
    data
  })
}

export function updateDepartment(data) {
  return request({
    url: `/department/${data.departmentId}`,
    method: 'put',
    data
  })
}

export function deleteDepartment(data) {
  return request({
    url: `/department/${data.departmentId}`,
    method: 'delete'
  })
}

export function getSchoolDepartment(query) {
  return request({
    url: '/school' + serialize(query),
    method: 'get'
  })
}

export function getSchoolNotDepartment() {
  return request({
    url: '/school/not-in-any-department',
    method: 'get'
  })
}

export function createSchool(data) {
  return request({
    url: '/school',
    method: 'post',
    data
  })
}

export function deleteSchool(data) {
  return request({
    url: `/school/${data.schoolId}`,
    method: 'delete'
  })
}

export function getEasyclassData(data) {
  return request({
    url: '/homework/easyclass-homework-data',
    method: 'post',
    data
  })
}

export function getEasyForKidsData(data) {
  return request({
    url: '/homework/easyforkids-homework-data',
    method: 'post',
    data
  })
}

export function getEasyCertificateData(data) {
  return request({
    url: '/homework/easycertificate-homework-data',
    method: 'post',
    data
  })
}

export function getUnistartData(data) {
  return request({
    url: '/homework/unistart-homework-data',
    method: 'post',
    data
  })
}

export function getClassHomeworkReport(data) {
  return request({
    url: '/class/homework-report',
    method: 'post',
    data
  })
}

export function getClassOverviewReport(data) {
  return request({
    url: '/class/overview-report',
    method: 'post',
    data
  })
}

export function getNotifications(query) {
  return request({
    url: '/notifications' + serialize(query),
    method: 'get'
  })
}

export function getNotificationUnseenCount(query) {
  return request({
    url: '/notifications/unseen-count' + serialize(query),
    method: 'get'
  })
}

export function getNews(query) {
  return request({
    url: '/news' + serialize(query),
    method: 'get'
  })
}

export function getNewsDetail(query) {
  return request({
    url: '/news/detail' + serialize(query),
    method: 'get'
  })
}

export function updateNotification(query) {
  return request({
    url: '/notifications/status' + serialize(query),
    method: 'put'
  })
}

export function getOverviewReport(data) {
  return request({
    url: '/statistics/overview-report',
    method: 'post',
    data
  })
}
export function getClassesByCreatedAt(data) {
  return request({
    url: '/statistics/classes',
    method: 'post',
    data
  })
}

export function getOverviewChartWeek(data) {
  return request({
    url: '/statistics/overview-chart-week',
    method: 'post',
    data
  })
}
export function getOverviewChartMonth(data) {
  return request({
    url: '/statistics/overview-chart-month',
    method: 'post',
    data
  })
}

export function getSchoolAveragePointsWeek(data) {
  return request({
    url: '/statistics/school-average-points-week',
    method: 'post',
    data
  })
}

export function getSchoolAveragePointsMonth(data) {
  return request({
    url: '/statistics/school-average-points-month',
    method: 'post',
    data
  })
}

export function getGroupPlans(data) {
  return request({
    url: '/group/schools-plan' + serialize(data),
    method: 'get'
  })
}

export function updateSchoolPlan(data) {
  return request({
    url: '/group/schools-plan',
    method: 'put',
    data
  })
}

export function getPricingPlans(data) {
  return request({
    url: '/plans' + serialize(data),
    method: 'get'
  })
}

export function activeLicense(data) {
  return request({
    url: '/plans/active',
    method: 'post',
    timeout: false,
    data: data
  })
}

export function registerPricingPlans(data) {
  return request({
    url: '/plans/register',
    method: 'post',
    data
  })
}

export function getECPointHistories(data) {
  return request({
    url: '/student/ec-point-histories' + serialize(data),
    method: 'get'
  })
}

export function getECStatistics(data) {
  return request({
    url: '/student/ec-statistics' + serialize(data),
    method: 'get'
  })
}

export function getEAContestHistories(data) {
  return request({
    url: '/student/ea-contest-histories' + serialize(data),
    method: 'get'
  })
}

export function getBASeasonOverview(data) {
  return request({
    url: '/student/ba-season-overview' + serialize(data),
    method: 'get'
  })
}

export function getECUserDetail(data) {
  return request({
    url: '/student/ec-user-detail' + serialize(data),
    method: 'get'
  })
}

export function getECMissionStatistic(data) {
  return request({
    url: '/student/ec-mission-statistic' + serialize(data),
    method: 'get'
  })
}

export function getECMissionStatisticDetail(data) {
  return request({
    url: '/student/ec-mission-statistic-detail' + serialize(data),
    method: 'get'
  })
}

export function getStudentHomeworks(data) {
  return request({
    url: '/student/homeworks' + serialize(data),
    method: 'get'
  })
}

export function getLessons(data) {
  return request({
    url: '/lessons' + serialize(data),
    method: 'get'
  })
}

export function createLesson(data) {
  return request({
    url: '/lessons',
    method: 'post',
    data
  })
}

export function updateLesson(data) {
  return request({
    url: '/lessons',
    method: 'put',
    data
  })
}

export function deleteLesson(data) {
  return request({
    url: '/lessons',
    method: 'delete',
    data
  })
}

export function score(data) {
  return request({
    url: '/homework/score' + serialize(data),
    method: 'post',
    data
  })
}

export function getMeeting(data) {
  return request({
    url: '/meetings',
    method: 'post',
    data
  })
}

export function getMpData(data) {
  return request({
    url: '/homework/mp-homework-data',
    method: 'post',
    data
  })
}

export function getLeaderPlans(data) {
  return request({
    url: '/teaching-plan/leader-plan' + serialize(data),
    method: 'get'
  })
}

export function getLeaderPlanHistory(data) {
  return request({
    url: '/teaching-plan/leader-plan-history' + serialize(data),
    method: 'get'
  })
}

export function getLessonPlans(data) {
  return request({
    url: '/teaching-plan/lesson-plan' + serialize(data),
    method: 'get'
  })
}

export function getTeacherPlans(data) {
  return request({
    url: '/teaching-plan/teacher-plan' + serialize(data),
    method: 'get'
  })
}

export function createLeaderPlan(data) {
  return request({
    url: '/teaching-plan/create-leader-plan',
    method: 'post',
    data
  })
}

export function updateStatusLeaderPlan(data) {
  return request({
    url: '/teaching-plan/update-leader-plan-status',
    method: 'post',
    data
  })
}

export function createLessonPlan(data) {
  return request({
    url: '/teaching-plan/create-lesson-plan',
    method: 'post',
    data
  })
}

export function updateStatusLessonPlan(data) {
  return request({
    url: '/teaching-plan/update-lesson-plan-status',
    method: 'post',
    data
  })
}

export function createTeacherPlan(data) {
  return request({
    url: '/teaching-plan/create-teacher-plan',
    method: 'post',
    data
  })
}

export function updateStatusTeacherPlan(data) {
  return request({
    url: '/teaching-plan/update-teacher-plan-status',
    method: 'post',
    data
  })
}

export function updateLeaderPlan(data) {
  return request({
    url: '/teaching-plan/update-leader-plan',
    method: 'post',
    data
  })
}

export function updateLessonPlan(data) {
  return request({
    url: '/teaching-plan/update-lesson-plan',
    method: 'post',
    data
  })
}

export function updateTeacherPlan(data) {
  return request({
    url: '/teaching-plan/update-teacher-plan',
    method: 'post',
    data
  })
}

export function uploadPlanAttachment(data, handleProgress, cancel) {
  const upload = new FormData()
  upload.append('file', data.file)

  return request({
    url: '/teaching-plan/upload-attachments' + serialize({ type: data.type, planId: data.planId, name: data.file.name, size: data.file.size }),
    method: 'put',
    timeout: false,
    data: upload,
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    onUploadProgress: handleProgress,
    cancelToken: new CancelToken(function executor(c) {
      if (cancel) {
        cancel(c)
      }
    })
  })
}

export function getLeaderPlan(data) {
  return request({
    url: '/teaching-plan/leader-plan-detail' + serialize(data),
    method: 'get'
  })
}

export function getLessonPlan(data) {
  return request({
    url: '/teaching-plan/lesson-plan-detail' + serialize(data),
    method: 'get'
  })
}

export function getTeacherPlan(data) {
  return request({
    url: '/teaching-plan/teacher-plan-detail' + serialize(data),
    method: 'get'
  })
}

export function getLiveExams(query) {
  return request({
    url: '/live-exam' + serialize(query),
    method: 'get'
  })
}

export function getLiveExam(data) {
  return request({
    url: '/live-exam/live/' + data.liveExamId + '/detail',
    method: 'get'
  })
}

export function createQuestionLiveExam(data) {
  return request({
    url: '/live-exam',
    method: 'post',
    data
  })
}

// export function pushQGQuestionLiveExam(data) {
//   return request({
//     url: '/homework/es-homework-push-qgs',
//     method: 'put',
//     data
//   })
// }

// export function pullQGQuestionLiveExam(data) {
//   return request({
//     url: '/homework/es-homework-pull-qgs',
//     method: 'put',
//     data
//   })
// }

export function updateQuestionLiveExam(data) {
  return request({
    url: '/live-exam/' + data._id + serialize({ classId: data.classId }),
    method: 'put',
    data
  })
}

// export function publishQuestionLiveExam(data) {
//   return request({
//     url: '/homework/es-homework/publish' + serialize({ classId: data.classId }),
//     method: 'put',
//     data
//   })
// }

export function searchStudents(data) {
  return request({
    url: '/student/searchUser',
    method: 'post',
    data
  })
}
