var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"base-input-question-select",style:(_vm.customStyle)},[(_vm.label)?_c('div',{staticClass:"input-label",staticStyle:{"text-align":"left","margin-bottom":"8px","width":"100%"}},[_c('label',{staticStyle:{"color":"#333","font-weight":"bold","position":"relative"},style:('font-size: ' + (_vm.labelSize ? _vm.labelSize : 14) + 'px'),domProps:{"innerHTML":_vm._s(_vm.label)}})]):_vm._e(),_c('md-field',{attrs:{"md-clearable":!_vm.multiple && _vm.clearable}},[(_vm.prefixIcon)?_c('md-icon',{staticStyle:{"position":"absolute","top":"7px","left":"10px"},style:(_vm.iconColor ? 'color: ' + _vm.iconColor : '')},[_vm._v(_vm._s(_vm.prefixIcon))]):_vm._e(),(!_vm.multiple && !_vm.isRemoteSearch)?_c('md-select',_vm._g(_vm._b({style:((_vm.formType == 'filter' ? 'border-radius: 4px;' : ';') +
        ('padding-left: ' +
          (_vm.prefixIcon ? '35px' : '16px') +
          '; padding-right: ' +
          (_vm.suffixIcon ? '35px;' : '16px;')) +
        (_vm.disabled ? 'opacity: 0.5; cursor: not-allowed' : '')),attrs:{"disabled":_vm.disabled,"value":_vm.value,"clearable":_vm.clearable,"placeholder":_vm.placeholder},on:{"input":function($event){return _vm.$emit('update', $event)}}},'md-select',_vm.$attrs,false),_vm.$listeners),_vm._l((_vm.selectOptions),function(opt){return _c('md-option',{key:opt[_vm.optKey] || opt,attrs:{"value":opt[_vm.optKey] || opt}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.valueFunc ? _vm.valueFunc(opt) : opt[_vm.optValue] || opt)}})])}),1):_c('el-select',_vm._g(_vm._b({staticStyle:{"margin-left":"20px"},style:((_vm.formType == 'filter' ? 'border-radius: 4px;' : ';') +
        ('padding-left: ' +
          (_vm.prefixIcon ? '35px' : '16px') +
          '; padding-right: ' +
          (_vm.suffixIcon ? '35px;' : '16px;')) +
        (_vm.disabled ? 'opacity: 0.5; cursor: not-allowed' : '')),attrs:{"disabled":_vm.disabled,"value":_vm.value,"multiple":_vm.multiple,"multiple-limit":_vm.multipleLimit,"collapse-tags":!_vm.createTag,"placeholder":_vm.placeholder,"loading":_vm.loading,"filterable":Boolean(_vm.remoteSearch) || _vm.filterable,"automatic-dropdown":true,"allow-create":_vm.allowCreate,"remote":"","remote-method":_vm.handleRemoteSearch},on:{"input":function($event){return _vm.$emit('update', $event)},"focus":function($event){_vm.remoteImediate && _vm.remoteSearch
          ? _vm.handleRemoteSearch({ search: '' })
          : ''}}},'el-select',_vm.$attrs,false),_vm.$listeners),[(_vm.multiple && _vm.multipleLimit > 1 && !_vm.createTag && _vm.enableSelectAll)?_c('div',{staticClass:"md-layout button-all",class:_vm.value && _vm.value.length === _vm.selectOptions.length ? 'selected-all' : ''},[_c('md-button',{staticStyle:{"text-transform":"none","float":"left"},on:{"click":_vm.handleSelectAll}},[_c('span',[_vm._v(_vm._s(_vm.selectAllLabel))])])],1):_vm._e(),(_vm.multiple && !_vm.createTag && _vm.enableSelectAll)?_c('hr'):_vm._e(),_vm._l((_vm.selectOptions),function(opt){return _c('el-option',{key:_vm.isNil(opt[_vm.optKey]) ? opt : opt[_vm.optKey],attrs:{"label":_vm.isNil(opt[_vm.optValue]) ? opt : opt[_vm.optValue],"value":_vm.valueFunc ? _vm.valueFunc(opt) : _vm.isNil(opt[_vm.optKey]) ? opt : opt[_vm.optKey]}})})],2),(_vm.suffixIcon)?_c('md-icon',{staticStyle:{"position":"absolute","top":"7px","right":"10px"},style:(_vm.iconColor ? 'color: ' + _vm.iconColor : '')},[_vm._v(_vm._s(_vm.suffixIcon))]):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }