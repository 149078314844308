<template>
  <div class="exam-vault-container">
    <h1
      style="
        width: 100%;
        text-align: left;
        padding-bottom: 12px;
        display: flex;
        align-items: center;
      "
    >
      {{ $t('MENU_EXAM_VAULT') }}
    </h1>

    <el-tabs v-model="activeName" @tab-click="handleClickTab">
      <el-tab-pane
        v-if="ownerCategories !== 'Teacher'"
        :label="$t('ORG_GROUP')"
        name="Group"
        lazy
      >
        <ExamVaultCategory
          ref="groupCategory"
          ownerCategory="Group"
          :editable="true"
          @select-category="handleSelectCategory('Group', $event)"
          @editExam="handleEdit"
          @deleteExam="handleDelete"
          @assignHomework="handleAssignHomework"
        />
      </el-tab-pane>

      <el-tab-pane
        v-if="ownerCategories === 'School'"
        :label="$t('SCHOOL')"
        name="School"
        lazy
      >
        <ExamVaultCategory
          ref="schoolCategory"
          ownerCategory="School"
          :editable="true"
          @select-category="handleSelectCategory('School', $event)"
          @editExam="handleEdit"
          @deleteExam="handleDelete"
          @assignHomework="handleAssignHomework"
        />
      </el-tab-pane>

      <el-tab-pane
        v-if="ownerCategories === 'Teacher' || ownerCategories === 'School'"
        :label="$t('MENU_PROFILE')"
        name="Teacher"
        lazy
      >
        <ExamVaultCategory
          ref="teacherCategory"
          ownerCategory="Teacher"
          :editable="true"
          @select-category="handleSelectCategory('Teacher', $event)"
          @editExam="handleEdit"
          @deleteExam="handleDelete"
          @assignHomework="handleAssignHomework"
        />
      </el-tab-pane>
    </el-tabs>

    <!-- Create Dialog -->
    <md-dialog :md-active.sync="examDialog.active">
      <md-dialog-title style="display: flex; justify-content: space-between">
        <div class="title">{{ $t('ADD_EXAM_VAULT') }}</div>
        <md-button
          class="md-icon-button"
          @click.prevent="examDialog.active = false"
        >
          <md-icon>close</md-icon>
        </md-button>
      </md-dialog-title>
      <md-dialog-content>
        <div class="input-field">
          <BaseInputQuestionText
            :placeholder="$t('COMMON_TITLE')"
            :label="$t('COMMON_TITLE')"
            :labelSize="14"
            v-model="examTemp.title"
          />
        </div>

        <div class="input-field">
          <BaseInputQuestionTextArea
            :label="$t('COMMON_DESCRIPTION')"
            :labelSize="14"
            v-model="examTemp.description"
          />
        </div>

        <div class="input-field">
          <label style="font-size: 14px; display: block; margin-bottom: 8px">{{
            $t('CATEGORIES')
          }}</label>
          <treeselect
            v-model="examTemp.categoryIds"
            :multiple="true"
            :options="getCategories()"
            :normalizer="normalizeCategory"
            placeholder="Select categories"
            value-consists-of="BRANCH_PRIORITY"
          />
        </div>

        <div class="input-field">
          <BaseInputSelectV2
            :label="$t('SUBJECT')"
            :labelSize="14"
            :options="config.subjects"
            :optValue="`${lang}Value`"
            v-model="examTemp.subject"
          />
        </div>

        <div class="input-field">
          <BaseInputSelectV2
            :label="$t('TYPE')"
            :labelSize="14"
            :options="config.productTypes"
            :optValue="`${lang}Value`"
            v-model="examTemp.productType"
          />
        </div>

        <div
          v-if="examTemp.subject === config.englishSubjectId"
          class="input-field"
        >
          <BaseInputSelectV2
            :label="$t('SKILL')"
            :labelSize="14"
            :options="config.skills"
            :optValue="`${lang}Value`"
            :clearable="true"
            v-model="examTemp.skill"
          />
        </div>

        <!-- <div class="input-field">
          <BaseInputSelectV2
            :label="$t('COMMON_TAGS')"
            :labelSize="14"
            :filterable="true"
            :allowCreate="true"
            v-model="examTemp.tags"
            :createTag="true"
            multiple
          />
        </div> -->

        <div class="btn-block">
          <md-button
            class="md-raised"
            @click.prevent="examDialog.active = false"
          >
            {{ $t('CANCEL') }}
          </md-button>
          <md-button
            class="md-raised md-primary text-white"
            @click.prevent="handleUpdateExam"
          >
            {{ examDialog.type === 'create' ? $t('CREATE') : $t('UPDATE') }}
          </md-button>
        </div>
      </md-dialog-content>
    </md-dialog>

    <!-- Create Homework Dialog -->
    <md-dialog :md-active.sync="hwDialog.active">
      <md-dialog-title style="display: flex; justify-content: space-between">
        <div class="title">{{ $t('ASSIGN_NEW') }}</div>
        <md-button class="md-icon-button" @click.prevent="hw.active = false">
          <md-icon>close</md-icon>
        </md-button>
      </md-dialog-title>
      <md-dialog-content>
        <div class="input-field">
          <BaseInputSelectV2
            :label="$t('CLASS')"
            :labelSize="14"
            :options="classes"
            :optValue="name"
            v-model="hwTemp.classId"
            @update="handleChangeClass"
          />
        </div>

        <div class="input-field">
          <BaseInputQuestionText
            :placeholder="$t('HW_NAME')"
            :label="$t('HW_NAME')"
            :labelSize="14"
            v-model="hwTemp.name"
          />
        </div>

        <div class="input-field">
          <BaseInputSelectV2
            :multiple="true"
            :options="getStudents()"
            :multipleLimit="0"
            :enableSelectAll="true"
            opt-value="fullname"
            :placeholder="$t('ASSIGNEES')"
            :label="$t('ASSIGNEES')"
            v-model="hwTemp.assignStudentIds"
            @update="handleChangeAssignStudent"
          />
        </div>

        <div class="input-field">
          <BaseDatePicker
            :placeholder="$t('EXER_ASSIGN_DATE')"
            :label="$t('EXER_ASSIGN_DATE')"
            v-model="hwTemp.startAt"
          />
        </div>

        <div class="input-field">
          <BaseDatePicker
            :placeholder="$t('EXER_DEADLINE_DATE')"
            :label="$t('EXER_DEADLINE_DATE')"
            v-model="hwTemp.endAt"
          />
        </div>

        <div class="input-field">
          <BaseInputText
            :placeholder="$t('EXER_TRY_TIMES')"
            :label="$t('EXER_TRY_TIMES')"
            :labelSize="18"
            :max="100"
            type="number"
            v-model="hwTemp.limitSubmit"
          />
        </div>

        <div class="input-field">
          <BaseCheckbox :label="$t('PUBLISH')" v-model="hwTemp.publish" />
        </div>

        <div class="btn-block">
          <md-button
            class="md-raised"
            @click.prevent="examDialog.active = false"
          >
            {{ $t('CANCEL') }}
          </md-button>
          <md-button
            class="md-raised md-primary text-white"
            @click.prevent="createHomework"
          >
            {{ $t('CREATE') }}
          </md-button>
        </div>
      </md-dialog-content>
    </md-dialog>
  </div>
</template>

<script>
import _ from 'lodash'
import { mapState } from 'vuex'
import ExamVaultCategory from './components/ExamCategory.vue'
import examVaultApi from '@/api/exam-vault'
import Treeselect from '@riophae/vue-treeselect'
// import '@riophae/vue-treeselect/dist/vue-treeselect.css'

export default {
  name: 'ExamVaultList',
  components: {
    ExamVaultCategory,
    Treeselect
  },

  data() {
    return {
      activeName: 'Group',
      selectedSubject: null,
      selectedCategoryId: null,
      selectedProductType: null,
      selectedSkill: null,
      searchQuery: '',
      examDialog: {
        active: false,
        type: 'create'
      },
      hwDialog: {
        active: false,
        exam: null
      },
      hwTemp: {},
      examTemp: {
        categoryIds: []
      },
      lang: 'vi'
    }
  },

  computed: {
    ...mapState('exam-vault', ['accessLevel', 'exams', 'config']),
    ...mapState('content', ['classes', 'students']),
    ...mapState('user', ['orgCategory', 'profile']),
    ownerCategories() {
      if (this.orgCategory === 'school') {
        return 'School'
      } else if (this.orgCategory === 'group') {
        return 'Group'
      } else {
        return 'Teacher'
      }
    }
  },

  created() {
    this.lang = this.$i18n.locale
    this.$store.dispatch('content/getClasses', {
      teacherId: this.profile._id,
      status: 'open',
      contestClass: false
    })
  },

  methods: {
    async createHomework() {
      try {
        await examVaultApi.createHomework(this.hwTemp)
        this.hwDialog.active = false
        this.hwDialog.exam = null

        this.$notify({
          type: 'success',
          title: this.$t('M_TITLE_SUCCESS'),
          message: this.$t('M_EXER_CREATE_SUCCESS')
        })
      } catch (error) {}
    },

    handleAssignHomework(exam) {
      this.hwTemp = {
        examVaultId: exam._id,
        classId: null,
        name: '',
        startAt: '',
        endAt: '',
        assignStudentIds: [],
        limitSubmit: 1,
        publish: false
      }
      this.hwDialog = {
        active: true,
        exam
      }
    },

    handleChangeClass() {
      this.$store.dispatch('content/getStudents', {
        classId: this.hwTemp.classId
      })
    },

    getStudents() {
      return this.students.filter(
        (d) =>
          !_.isNil(_.get(d, 'link.easy_class.id')) && d.status === 'approved'
      )
    },

    handleChangeAssignStudent() {
      this.assignAll = this.assignStudentIds.length === this.students.length
    },

    handleSelectCategory(tabName, categoryId) {
      this.selectedCategoryId = categoryId
    },

    handleClickTab(tab) {
      this.activeName = tab.name
    },

    // Refresh the active exam component based on the current tab
    refreshActiveExamComponent() {
      const refMap = {
        Teacher: 'teacherCategory',
        School: 'schoolCategory',
        Group: 'groupCategory'
      }

      const refName = refMap[this.activeName]
      if (this.$refs[refName]) {
        this.$refs[refName].refreshExams()
      }
    },

    getCategories() {
      const refMap = {
        Teacher: 'teacherCategory',
        School: 'schoolCategory',
        Group: 'groupCategory'
      }

      const refName = refMap[this.activeName]
      if (this.$refs[refName] && this.$refs[refName].categories) {
        return this.$refs[refName].categories
      }
      return []
    },

    normalizeCategory(node) {
      return {
        id: node._id || node.id,
        label: node.name,
        children: node.children
      }
    },

    handleEdit(event, data) {
      if (event === 'create') {
        this.examTemp = {
          title: '',
          description: '',
          tags: [],
          subject: null,
          productType: null,
          skill: null,
          categoryIds: [this.selectedCategoryId]
        }
      } else if (event === 'update') {
        this.examTemp = {
          _id: data._id,
          title: data.title,
          description: data.description,
          tags: data.tags,
          subject: data?.subject?._id,
          productType: data?.productType?._id,
          skill: data?.skill?._id,
          categoryIds: data.categoryIds
        }
      }

      this.examDialog.active = true
      this.examDialog.type = event
    },

    async handleDelete(data) {
      await this.$store.dispatch('exam-vault/deleteExam', data._id)
      // Refresh the active exam component
      this.refreshActiveExamComponent()
    },

    async handleUpdateExam() {
      try {
        if (this.examDialog.type === 'create') {
          await this.$store.dispatch('exam-vault/createExam', {
            ...this.examTemp,
            ownerCategory: this.activeName
          })
          this.examDialog.active = false
          // Refresh the active exam component
          this.refreshActiveExamComponent()
        } else {
          await this.$store.dispatch('exam-vault/updateExam', {
            ...this.examTemp,
            ownerCategory: this.activeName
          })
          this.examDialog.active = false
          // Refresh the active exam component
          this.refreshActiveExamComponent()
        }
      } catch (error) {
        // console.error('Error during exam update:', error)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.exam-vault-container {
  padding: 24px;
  min-height: 100vh;

  .top-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .filters-section {
      display: flex;
      width: 100%;
      justify-content: start;
      flex-wrap: wrap;

      .search-box {
        min-width: 250px;
      }

      .filter-control {
        margin-right: 10px;
      }
    }
  }

  .error-state,
  .empty-state {
    text-align: center;
    padding: 50px;
  }

  .exams-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
  }

  .exam-card {
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 15px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }

  .exam-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  }

  .exam-meta {
    margin: 10px 0;
  }

  .badge {
    margin-right: 5px;
  }

  .tags-container {
    margin-top: 10px;
  }

  .tag {
    background-color: #e9ecef;
    border-radius: 15px;
    padding: 2px 8px;
    font-size: 12px;
    margin-right: 5px;
    display: inline-block;
    margin-bottom: 5px;
  }

  .card-actions {
    margin-top: 15px;
    text-align: right;
  }
}

.input-field {
  margin-bottom: 15px;
}
</style>
