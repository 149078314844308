import examVaultService from '@/api/exam-vault'

const state = {
  exams: [],
  loading: false,
  error: null,
  config: {
    subjects: [],
    productTypes: [],
    skills: [],
    englishSubjectId: ''
  },
  filters: {
    subject: null,
    productType: null,
    skill: null,
    search: ''
  },
  pagination: {
    page: 1,
    limit: 10,
    total: 0
  }
}

const mutations = {
  SET_EXAMS(state, exams) {
    state.exams = exams
  },
  SET_CURRENT_EXAM(state, exam) {
    state.currentExam = exam
  },
  SET_LOADING(state, isLoading) {
    state.loading = isLoading
  },
  SET_ERROR(state, error) {
    state.error = error
  },
  SET_CONFIG(state, config) {
    // Check if config is an array, if not, handle appropriately
    const configArray = Array.isArray(config)
      ? config
      : Object.entries(config).map(([key, value]) => ({ key, value }))
    state.config.subjects = configArray.filter((itm) => itm.key === 'subject')
    state.config.productTypes = configArray.filter(
      (itm) => itm.key === 'productType'
    )
    state.config.skills = configArray.filter((itm) => itm.key === 'skill')

    for (const item of state.config?.subjects || []) {
      if (item.value === 'english') {
        state.config.englishSubjectId = item._id
      }
    }
  },
  SET_PAGINATION(state, { key, value }) {
    state.pagination[key] = value
  },
  UPDATE_PAGINATION(state, data) {
    state.pagination.page = data.page
    state.pagination.limit = data.limit
    state.pagination.total = data.total
  },
  SET_FILTER(state, { key, value }) {
    state.filters[key] = value
  },
  RESET_FILTERS(state) {
    state.filters = {
      subject: null,
      productType: null,
      skill: null,
      search: ''
    }
  }
}

const actions = {
  async fetchExams({ commit, state }) {
    commit('SET_LOADING', true)
    try {
      const query = {
        ...state.filters,
        page: state.pagination.page,
        limit: state.pagination.limit
      }
      if (
        this.accessLevel &&
        ['Teacher', 'School', 'Group'].includes(this.accessLevel)
      ) {
        query.accessLevel = this.accessLevel
      }
      const response = await examVaultService.getExams(query)
      commit('SET_EXAMS', response.data.list)
      commit('SET_ACCESS_LEVEL', response.data.accessLevel)
      commit('UPDATE_PAGINATION', response.data.pagination)
    } catch (error) {
      commit('SET_ERROR', error.message || 'Failed to fetch exams')
    } finally {
      commit('SET_LOADING', false)
    }
  },

  async fetchExamById({ commit }, id) {
    commit('SET_LOADING', true)
    try {
      const response = await examVaultService.getExamById(id)
      return response.data.exam
    } catch (error) {
      commit('SET_ERROR', error.message || 'Failed to fetch exam details')
    } finally {
      commit('SET_LOADING', false)
    }
  },

  async fetchExamConfig({ commit }) {
    commit('SET_LOADING', true)
    try {
      const response = await examVaultService.getExamConfig()
      commit('SET_CONFIG', response.data.config)
    } catch (error) {
      commit(
        'SET_ERROR',
        error.message || 'Failed to fetch exam configurations'
      )
      console.error(error)
    } finally {
      commit('SET_LOADING', false)
    }
  },

  setPagination({ commit, dispatch }, page) {
    commit('SET_PAGINATION', page)
    dispatch('fetchExams')
  },

  setFilter({ commit, dispatch }, { key, value }) {
    commit('SET_FILTER', { key, value })
    dispatch('fetchExams')
  },

  resetFilters({ commit, dispatch }) {
    commit('RESET_FILTERS')
    dispatch('fetchExams')
  },

  async createExam({ commit, dispatch }, exam) {
    try {
      await examVaultService.createExam(exam)
    } catch (error) {
      // commit('SET_ERROR', error.message || 'Failed to create exam')
    }
  },

  async updateExam({ commit, dispatch }, exam) {
    try {
      await examVaultService.updateExam(exam)
    } catch (error) {
      // commit('SET_ERROR', error.message || 'Failed to update exam')
    }
  },

  async deleteExam({ commit, dispatch }, id) {
    try {
      await examVaultService.deleteExam(id)
    } catch (error) {
      // commit('SET_ERROR', error.message || 'Failed to delete exam')
    }
  }
}

const getters = {
  filteredExams: (state) => {
    return state.exams
  },

  isLoading: (state) => state.loading,

  hasError: (state) => !!state.error
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
