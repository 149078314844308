<template>
  <div class="exam-preview container">
    <div
      v-for="(questionGroup, qInd) in questionGroups"
      :key="questionGroup._id"
    >
      <div>
        <strong
          >Bài {{ qInd + 1 }}: <span v-html="questionGroup.title"></span
        ></strong>
      </div>
      <div class="md-layout">
        <div
          v-if="questionGroup.audioUrl"
          class="md-layout-item md-size-50 audio-preview"
        >
          <audio
            controls
            :src="questionGroup.audioUrl | googleDriveQuestionMedia"
          ></audio>
        </div>
        <div v-if="questionGroup.imgUrl" class="md-layout-item md-size-50">
          <img :src="questionGroup.imgUrl" />
        </div>
      </div>
      <div
        v-for="question in questionGroup.questions"
        :key="question._id"
        class="question-preview"
      >
        <div class="content"><p v-html="question.content"></p></div>
        <div class="md-layout">
          <div
            v-if="question.audioUrl"
            class="md-layout-item md-size-50 audio-preview"
          >
            <audio
              controls:src="question.audioUrl | googleDriveQuestionMedia"
            ></audio>
          </div>
          <div v-if="question.imgUrl" class="md-layout-item md-size-50">
            <img :src="question.imgUrl" />
          </div>
        </div>
        <div class="answers-section">
          <QuestionAnswerPreview
            :key="'preview-' | randomKey"
            :answers="question.answers"
            :rightAnswer="question.right"
            :questionType="question.type"
            :fakeChoices="question.fakeChoices"
            :studentAnswer="{
              isRight: true
            }"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import QuestionAnswerPreview from '@/components/question/QuestionAnswerPreview.vue'

export default {
  name: 'ExamPreview',
  components: {
    QuestionAnswerPreview
  },
  props: {
    questionGroups: {
      type: Array,
      required: true
    }
  }
}
</script>

<!-- <style lang="scss">
.exam-preview {
}
</style> -->
