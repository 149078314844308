var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"base-input-select",style:(_vm.customStyle)},[(_vm.label)?_c('div',{staticClass:"input-label",staticStyle:{"text-align":"left","margin-bottom":"8px","width":"100%"}},[_c('label',{staticStyle:{"color":"#333","font-weight":"bold","position":"relative"},style:('font-size: ' + (_vm.labelSize ? _vm.labelSize : 14) + 'px')},[_vm._v(" "+_vm._s(_vm.label)+" ")])]):_vm._e(),_c('md-field',{attrs:{"md-clearable":!_vm.multiple && _vm.clearable}},[(_vm.prefixIcon)?_c('md-icon',{staticStyle:{"position":"absolute","top":"7px","left":"10px"},style:(_vm.iconColor ? 'color: ' + _vm.iconColor : '')},[_vm._v(_vm._s(_vm.prefixIcon))]):_vm._e(),(!_vm.multiple && !_vm.isRemoteSearch)?_c('el-select',_vm._g(_vm._b({style:((_vm.formType == 'filter' ? 'border-radius: 4px;' : ';') +
        ('padding-left: ' +
          (_vm.prefixIcon ? '35px' : '16px') +
          '; padding-right: ' +
          (_vm.suffixIcon ? '35px;' : '6px;')) +
        (_vm.disabled ? 'opacity: 0.5; cursor: not-allowed' : '') +
        (_vm.width ? 'width: ' + _vm.width + '!important;' : '')),attrs:{"disabled":_vm.disabled,"value":_vm.value,"clearable":_vm.clearable,"placeholder":_vm.placeholder,"placement":_vm.placement,"fallback-placements":_vm.fallbackPlacements},on:{"input":function($event){return _vm.$emit('update', $event)}}},'el-select',_vm.$attrs,false),_vm.$listeners),_vm._l((_vm.selectOptions),function(opt){return _c('el-option',{key:opt[_vm.optKey] || opt,attrs:{"label":opt[_vm.optValue] || opt,"value":opt[_vm.optKey] || opt}})}),1):_c('el-select',_vm._g(_vm._b({staticStyle:{"margin-left":"20px"},style:((_vm.formType == 'filter' ? 'border-radius: 4px;' : ';') +
        ('padding-left: ' +
          (_vm.prefixIcon ? '35px' : '16px') +
          '; padding-right: ' +
          (_vm.suffixIcon ? '35px;' : '4px;')) +
        (_vm.disabled ? 'opacity: 0.5; cursor: not-allowed' : '') +
        (_vm.width ? 'width: ' + _vm.width + '!important;' : '')),attrs:{"disabled":_vm.disabled,"value":_vm.value,"multiple":_vm.multiple,"clearable":_vm.clearable,"multiple-limit":_vm.multipleLimit,"collapse-tags":"","placeholder":_vm.placeholder,"placement":_vm.placement,"fallback-placements":_vm.fallbackPlacements,"loading":_vm.loading,"filterable":Boolean(_vm.remoteSearch),"automatic-dropdown":true,"remote":"","remote-method":_vm.handleRemoteSearch,"value-key":"id"},on:{"input":function($event){return _vm.$emit('update', $event)},"focus":function($event){_vm.remoteImediate && _vm.remoteSearch ? _vm.handleRemoteSearch('') : ''}}},'el-select',_vm.$attrs,false),_vm.$listeners),[(_vm.multiple && _vm.enableSelectAll)?_c('div',{staticClass:"md-layout button-all",class:_vm.value && _vm.value.length === _vm.selectOptions.length ? 'selected-all' : ''},[_c('md-button',{staticStyle:{"text-transform":"none","float":"left"},on:{"click":_vm.handleSelectAll}},[_c('span',[_vm._v(_vm._s(_vm.selectAllLabel))])])],1):_vm._e(),(_vm.multiple && _vm.enableSelectAll)?_c('hr'):_vm._e(),_vm._l((_vm.selectOptions),function(opt){return _c('el-option',{key:opt[_vm.optKey && _vm.optKey !== '' ? _vm.optKey : _vm.optValue] || opt,attrs:{"label":opt[_vm.optValue] || opt,"value":opt[_vm.optKey] || opt}})})],2),(_vm.suffixIcon)?_c('md-icon',{staticStyle:{"position":"absolute","top":"7px","right":"10px"},style:(_vm.iconColor ? 'color: ' + _vm.iconColor : '')},[_vm._v(_vm._s(_vm.suffixIcon))]):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }