import Vue from 'vue'
import { IonicVueRouter } from '@ionic/vue'
import AppLayout from '@/views/layout/App.vue'
import ExamVaultList from '@/views/ExamVault/ExamVaultList.vue'
import ExamVaultDetail from '@/views/ExamVault/ExamVaultDetail.vue'

Vue.use(IonicVueRouter)

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/Login.vue')
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('@/views/Register.vue')
  },
  {
    path: '/',
    name: 'Home',
    meta: {
      layout: AppLayout
    },
    component: () => import('@/views/Home.vue')
  },
  {
    path: '/tin-tuc/:newsUrl',
    name: 'NewsDetail',
    meta: {
      layout: AppLayout
    },
    component: () => import('@/views/NewsDetail.vue')
  },
  {
    path: '/truong-hoc/:tab',
    name: 'School',
    meta: {
      layout: AppLayout
    },
    component: () => import('@/views/School.vue')
  },
  {
    path: '/nha',
    name: 'MentorHouse',
    meta: {
      layout: AppLayout
    },
    component: () => import('@/views/mentor-house/index.vue')
  },
  {
    path: '/microsoft-redirect',
    name: 'OneDriveRedirect',
    meta: {
      layout: AppLayout
    },
    component: () => import('@/views/OneDriveRedirect.vue')
  },
  {
    path: '/plan',
    name: 'Plan',
    meta: {
      layout: AppLayout
    },
    component: () => import('@/views/plan/Plan.vue')
  },
  {
    path: '/plan-leader',
    name: 'PlanLeader',
    meta: {
      layout: AppLayout
    },
    component: () => import('@/views/plan/PlanLeader.vue')
  },
  {
    path: '/plan-leader-detail/:planUid',
    name: 'PlanLeaderDetail',
    meta: {
      layout: AppLayout
    },
    component: () => import('@/views/plan/PlanLeaderDetail.vue')
  },
  {
    path: '/plan-teacher',
    name: 'PlanTeacher',
    meta: {
      layout: AppLayout
    },
    component: () => import('@/views/plan/PlanTeacher.vue')
  },
  {
    path: '/plan-teacher-detail/:planUid',
    name: 'PlanTeacherDetail',
    meta: {
      layout: AppLayout
    },
    component: () => import('@/views/plan/PlanTeacherDetail.vue')
  },
  {
    path: '/lesson-plan',
    name: 'PlanLesson',
    meta: {
      layout: AppLayout
    },
    component: () => import('@/views/plan/PlanLesson.vue')
  },
  {
    path: '/lesson-plan-detail/:planUid',
    name: 'PlanLessonDetail',
    meta: {
      layout: AppLayout
    },
    component: () => import('@/views/plan/PlanLessonDetail.vue')
  },
  {
    path: '/lop-hoc',
    name: 'Class',
    meta: {
      layout: AppLayout
    },
    component: () => import('@/views/Class.vue')
  },
  {
    path: '/trung-tam-khao-thi',
    name: 'RTContest',
    meta: {
      layout: AppLayout
    },
    component: () => import('@/views/rtcontest/RTContest.vue')
  },
  {
    path: '/trung-tam-khao-thi/:contestId',
    name: 'RTContestDetail',
    meta: {
      layout: AppLayout
    },
    component: () => import('@/views/rtcontest/RTContestDetail.vue')
  },
  {
    path: '/bt-tuong-tac/:liveExamId',
    name: 'InteractiveHomework',
    meta: {
      layout: AppLayout
    },
    component: () => import('@/views/live-exam/LiveExam.vue')
  },
  {
    path: '/rung-chuong-vang',
    name: 'TRcvContest',
    meta: {
      layout: AppLayout
    },
    component: () => import('@/views/t-rcv-contest/index.vue')
  },
  {
    path: '/rung-chuong-vang/:contestId',
    name: 'TRcvContestDetail',
    meta: {
      layout: AppLayout
    },
    component: () => import('@/views/t-rcv-contest/detail.vue')
  },
  {
    path: '/lop-hoc/:classUid/rcv/:contestId',
    name: 'RCVContestDetail',
    meta: {
      layout: AppLayout
    },
    component: () => import('@/views/rcv-contest/detail.vue')
  },
  {
    path: '/bao-cao',
    name: 'Reports',
    meta: {
      layout: AppLayout
    },
    component: () => import('@/views/Reports.vue')
  },
  {
    path: '/bai-tap',
    name: 'ListClassHomework',
    meta: {
      layout: AppLayout
    },
    component: () => import('@/views/ListClassHomeworks.vue')
  },
  {
    path: '/bai-hoc',
    name: 'ListClassStudy',
    meta: {
      layout: AppLayout
    },
    component: () => import('@/views/ListClassStudy.vue')
  },
  {
    path: '/giao-vien',
    name: 'ListTeacher',
    meta: {
      layout: AppLayout
    },
    component: () => import('@/views/ListTeacher.vue')
  },
  {
    path: '/lop-hoc/:classUid',
    name: 'ClassDetail',
    meta: {
      layout: AppLayout
    },
    component: () => import('@/views/ClassDetail.vue')
  },
  {
    path: '/lop-hoc/:classUid/btvn-tai-len',
    name: 'ClassFileHomework',
    meta: {
      layout: AppLayout
    },
    component: () => import('@/views/ClassFileHomework.vue')
  },
  {
    path: '/lop-hoc/:classUid/easy-class/',
    name: 'EasyClassHomework',
    meta: {
      layout: AppLayout
    },
    component: () => import('@/views/EasyClassHomework.vue')
  },
  {
    path: '/lop-hoc/:classUid/unistart/',
    name: 'UnistartHomework',
    meta: {
      layout: AppLayout
    },
    component: () => import('@/views/UnistartHomework.vue')
  },
  {
    path: '/lop-hoc/mp/:classUid/:appId/',
    name: 'MpHomework',
    meta: {
      layout: AppLayout
    },
    component: () => import('@/views/MpHomework.vue')
  },
  {
    path: '/lop-hoc/:classUid/btvn-speaking',
    name: 'ClassAttachmentHomework',
    meta: {
      layout: AppLayout
    },
    component: () => import('@/views/ClassAttachmentHomework.vue')
  },
  {
    path: '/lop-hoc/:classUid/create-mission/:missionTemplate',
    name: 'CreateMission',
    meta: {
      layout: AppLayout
    },
    component: () => import('@/views/UpdateMission.vue')
  },
  {
    path: '/lop-hoc/:classUid/update-mission/:missionId',
    name: 'UpdateMission',
    meta: {
      layout: AppLayout
    },
    component: () => import('@/views/UpdateMission.vue')
  },
  {
    path: '/lop-hoc/:classUid/create-mission-chain',
    name: 'CreateMissionChain',
    meta: {
      layout: AppLayout
    },
    component: () => import('@/views/UpdateMissionChain.vue')
  },
  {
    path: '/lop-hoc/:classUid/update-mission-chain/:missionId',
    name: 'UpdateMissionChain',
    meta: {
      layout: AppLayout
    },
    component: () => import('@/views/UpdateMissionChain.vue')
  },
  {
    path: '/lop-hoc/:classUid/mission/:missionId',
    name: 'MissionDetail',
    meta: {
      layout: AppLayout
    },
    component: () => import('@/views/MissionDetail.vue')
  },
  {
    path: '/lop-hoc/:classUid/mission-chain/:missionId',
    name: 'MissionChainDetail',
    meta: {
      layout: AppLayout
    },
    component: () => import('@/views/MissionChainDetail.vue')
  },
  {
    path: '/lop-hoc/:classUid/btvn-thu-vien',
    name: 'DocumentHomework',
    meta: {
      layout: AppLayout
    },
    component: () => import('@/views/DocumentHomework.vue')
  },
  {
    path: '/lop-hoc/:classUid/btvn-cau-hoi',
    name: 'ClassQuestionHomework',
    meta: {
      layout: AppLayout
    },
    component: () => import('@/views/ClassQuestionHomework.vue')
  },
  {
    path: '/lop-hoc/:classUid/bttt-cau-hoi',
    name: 'ClassQuestionLiveExam',
    meta: {
      layout: AppLayout
    },
    component: () => import('@/views/live-exam/ClassQuestionLiveExam.vue')
  },
  {
    path: '/lop-hoc/:classUid/:homeworkUid',
    name: 'ClassHomeWorkDetail',
    meta: {
      layout: AppLayout
    },
    component: () => import('@/views/home-work/result/index.vue')
  },
  {
    path: '/lop-hoc/:classUid/race-challenge/:raceChallengeId',
    name: 'RaceChallengeDetail',
    meta: {
      layout: AppLayout
    },
    component: () => import('@/views/race-challenge/Detail.vue')
  },
  {
    path: '/hoc-sinh',
    name: 'Students',
    meta: {
      layout: AppLayout
    },
    component: () => import('@/views/Students.vue')
  },
  {
    path: '/hoc-sinh/:studentId',
    name: 'StudentDetail',
    meta: {
      layout: AppLayout
    },
    component: () => import('@/views/StudentDetail.vue')
  },
  {
    path: '/tai-lieu',
    name: 'Document',
    meta: {
      layout: AppLayout
    },
    component: () => import('@/views/Document.vue')
  },
  {
    path: '/tai-lieu/phan-quyen',
    name: 'Document-Permissions',
    meta: {
      layout: AppLayout
    },
    component: () => import('@/views/DocumentPermission.vue')
  },
  {
    path: '/su-kien',
    name: 'Event',
    meta: {
      layout: AppLayout
    },
    component: () => import('@/views/Event.vue')
  },
  {
    path: '/su-kien/:eventCode',
    name: 'EventContest',
    meta: {
      layout: AppLayout
    },
    component: () => import('@/views/Contest.vue')
  },
  {
    path: '/su-kien/bxh/:eventCode',
    name: 'EventRanking',
    meta: {
      layout: AppLayout
    },
    component: () => import('@/views/EventRanking.vue')
  },
  {
    path: '/su-kien/:eventCode/cuoc-thi/:contestCode',
    name: 'EventContestQuestion',
    meta: {
      layout: AppLayout
    },
    component: () => import('@/views/ContestQuestion.vue')
  },
  {
    path: '/pricing',
    name: 'PricingPlan',
    meta: {
      layout: AppLayout
    },
    component: () => import('@/views/PricingPlan.vue')
  },
  {
    path: '/huong-dan',
    name: 'Instruction',
    meta: {
      layout: AppLayout
    },
    component: () => import('@/views/instruction/Instruction.vue')
  },
  {
    path: '/huong-dan/:id',
    name: 'InstructionDetail',
    meta: {
      layout: AppLayout
    },
    component: () => import('@/views/instruction/InstructionDetail.vue')
  },
  {
    path: '/lien-he',
    name: 'Contact',
    meta: {
      layout: AppLayout
    },
    component: () => import('@/views/Contact.vue')
  },
  {
    path: '/profile',
    name: 'Profile',
    meta: {
      layout: AppLayout
    },
    component: () => import('@/views/Profile.vue')
  },
  {
    path: '/kho-hoc-lieu',
    name: 'ExamVault',
    component: ExamVaultList,
    meta: {
      layout: AppLayout
    }
  },
  {
    path: '/kho-hoc-lieu/:examVaultId',
    name: 'ExamVaultDetail',
    component: ExamVaultDetail,
    meta: { layout: AppLayout }
  }
]

export default new IonicVueRouter({
  mode: Vue.prototype.$myDevice === 'isMobileApp' ? 'hash' : 'history',
  base: process.env.BASE_URL,
  routes: routes
})
