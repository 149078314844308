<template>
  <div v-loading="loading" class="question-homework">
    <div
      class="md-layout-item md-small-size-100 md-xsmall-size-100"
      style="height: 60px"
      :style="$isMobileDevice ? 'margin-bottom: 40px' : ''"
    >
      <div
        class="md-layout w-100 header"
        style="color: #1cab36; margin: 0px 15px 10px 0px"
      >
        <div class="left-hw-btn">
          <md-button
            @click="goBack"
            class="md-icon-button"
            style="height: 58px; width: 58px; margin-right: 20px"
          >
            <md-icon
              style="
                font-size: 36px !important;
                color: #1cab36;
                line-height: 58px;
              "
              >arrow_back</md-icon
            >
          </md-button>

          <div v-if="exam && method !== 'create'" class="homework-title">
            {{ exam.title }}
          </div>
        </div>
      </div>
    </div>

    <div v-if="exam" class="question-layout md-layout">
      <div
        class="md-layout-item md-size-25 md-small-size-100 question-group-list"
        :style="$isMobileDevice ? 'margin-bottom: 24px;' : ''"
      >
        <div
          class="question-header"
          style="
            color: #1cab36;
            font-weight: bold;
            font-size: 16px;
            margin-bottom: 14px;
            margin-top: 14px;
          "
        >
          {{ $t('QUESTION_GROUP') }}
        </div>
        <div class="question-groups">
          <div
            v-for="(qg, index) in questionGroups"
            :key="qg._id"
            class="q-item"
            :class="
              currentQuestionGroup && qg._id === currentQuestionGroup._id
                ? 'q-item-selected'
                : ''
            "
          >
            <span class="title" @click="handleSelectQuestionGroup(qg)"
              >{{ $t('QUESTION_GROUP') }} {{ index + 1 }}</span
            >
            <div class="settings">
              <span @click="handleUpdateQuestionGroup('update', qg)"
                ><md-icon>settings</md-icon></span
              >
              <span @click="handleDelete('questionGroup', qg)"
                ><md-icon>delete</md-icon></span
              >
            </div>
          </div>
        </div>
        <div class="add-question-group-btn">
          <span class="add-icon" @click="handleUpdateQuestionGroup('create')">
            <md-icon
              >add<md-tooltip md-direction="bottom">{{
                $t('CREATE')
              }}</md-tooltip></md-icon
            >
          </span>
        </div>
      </div>
      <div
        class="md-layout-item md-size-25 md-small-size-100 question-list"
        :style="$isMobileDevice ? 'margin-bottom: 24px;' : ''"
      >
        <div
          class="question-header"
          style="
            color: #1cab36;
            font-weight: bold;
            font-size: 16px;
            margin-bottom: 14px;
            margin-top: 14px;
          "
        >
          {{ $t('QUESTION') }}
        </div>
        <div class="questions">
          <div
            v-for="(q, index) in questions"
            :key="q._id"
            class="q-item"
            :class="
              currentQuestion && q._id === currentQuestion._id
                ? 'q-item-selected'
                : ''
            "
            @click="handleSelectQuestion(q)"
          >
            <span class="title">{{ $t('QUESTION') }} {{ index + 1 }}</span>
            <div class="settings">
              <span @click="handleUpdateQuestion('update', q)"
                ><md-icon>settings</md-icon></span
              >
              <span @click="handleDelete('question', q)"
                ><md-icon>delete</md-icon></span
              >
            </div>
          </div>
        </div>
        <div
          class="add-question-btn"
          @click="handleUpdateQuestion('create')"
          v-if="currentQuestionGroup"
        >
          <span class="add-icon"
            ><md-icon
              >add<md-tooltip md-direction="bottom">{{
                $t('CREATE')
              }}</md-tooltip></md-icon
            ></span
          >
        </div>
      </div>
      <div class="md-layout-item md-size-50 preview-section">
        <div
          class="question-header"
          style="
            color: #1cab36;
            font-weight: bold;
            font-size: 16px;
            margin-bottom: 14px;
            margin-top: 14px;
          "
        >
          {{ $t('QUESTION_CONTENT') }}
        </div>
        <div v-if="currentQuestionGroup" class="question-group-preview">
          <div class="title">{{ currentQuestionGroup.title }}</div>
          <div class="content" v-html="currentQuestionGroup.content" />
          <div class="md-layout">
            <div
              v-if="currentQuestionGroup.audioUrl"
              class="md-layout-item md-size-50 audio-preview"
            >
              <audio
                controls
                :src="currentQuestionGroup.audioUrl | googleDriveQuestionMedia"
              ></audio>
            </div>
            <div
              v-if="currentQuestionGroup.imgUrl"
              class="md-layout-item md-size-50"
            >
              <img
                :src="currentQuestionGroup.imgUrl | googleDriveQuestionMedia"
              />
            </div>
          </div>
        </div>
        <div v-if="currentQuestion" class="question-preview">
          <div class="title">
            {{ $t(questionTypeFilter(currentQuestion.type)) }}
          </div>
          <div class="content" v-html="currentQuestion.content" />
          <div class="md-layout">
            <div
              v-if="currentQuestion.audioUrl"
              class="md-layout-item md-size-50 audio-preview"
            >
              <audio
                controls
                :src="currentQuestion.audioUrl | googleDriveQuestionMedia"
              ></audio>
            </div>
            <div
              v-if="currentQuestion.imgUrl"
              class="md-layout-item md-size-50"
            >
              <img :src="currentQuestion.imgUrl | googleDriveQuestionMedia" />
            </div>
          </div>

          <div class="answers-section">
            <QuestionAnswer
              :key="'preview-' | randomKey"
              :answers="currentQuestion.answers"
              :rightAnswer="currentQuestion.right"
              :questionType="currentQuestion.type"
              :fakeChoices="currentQuestion.fakeChoices"
              :preview="true"
            />
          </div>
        </div>
      </div>
    </div>

    <md-dialog
      :md-active.sync="questionDialog.visible"
      :md-fullscreen="true"
      class="question-group-dialog"
    >
      <md-dialog-title>
        <div class="title">{{ $t('QUESTION') }}</div>
        <BaseButton :label="$t('SAVE')" @click="updateQuestion" />
      </md-dialog-title>

      <md-dialog-content v-loading="questionDialog.loading">
        <div class="input-field">
          <BaseInputQuestionTextArea
            label="Nội dung câu hỏi"
            :labelSize="16"
            v-model="temp.content"
          />
          <!-- <div class="title" style="font-weight: bold; font-size: 16px">
            Nội dung câu hỏi
          </div>
          <Tinymce v-model="temp.content" :height="300" /> -->
        </div>
        <div class="md-layout md-gutter">
          <div class="md-layout-item">
            <BaseInputQuestionFile
              label="Hình ảnh"
              accept="image/*"
              :labelSize="16"
              :loading.sync="uploadLoading.imgUrl"
              v-model="temp.imgUrl"
              iconClass="image"
              :storageClass="config.storageClass"
              :file="file.imgUrl"
              @file-selected="
                (val) => {
                  handleUpload('imgUrl', val)
                }
              "
              @clear="clearFile('imgUrl')"
            />
          </div>
          <div class="md-layout-item">
            <BaseInputQuestionFile
              label="Âm thanh"
              :labelSize="16"
              accept="audio/*"
              :loading.sync="uploadLoading.audioUrl"
              v-model="temp.audioUrl"
              iconClass="audio"
              :storageClass="config.storageClass"
              :file="file.audioUrl"
              @file-selected="
                (val) => {
                  handleUpload('audioUrl', val)
                }
              "
              @clear="clearFile('audioUrl')"
            />
          </div>
        </div>

        <div class="input-field">
          <BaseInputQuestionSelect
            label="Dạng câu hỏi"
            :labelSize="16"
            v-model="temp.type"
            :options="[
              {
                _id: 'MultiChoice',
                name: 'Trắc nghiệm'
              },
              {
                _id: 'ShortAnswer',
                name: 'Điền đáp án đúng'
              },
              {
                _id: 'StringSorting',
                name: 'Sắp xếp chữ cái, từ'
              },
              {
                _id: 'Classification',
                name: 'Phân loại'
              },
              {
                _id: 'Speaking',
                name: 'Phát âm'
              },
              {
                _id: 'UnderlineWord',
                name: 'Gạch chân'
              },
              {
                _id: 'Matching',
                name: 'Nối đáp án đúng'
              },
              {
                _id: 'WordSearch',
                name: 'Tìm ô chữ'
              },
              {
                _id: 'CompleteSentence',
                name: 'Hoàn thành câu'
              }
            ]"
            :multiple="false"
          />
        </div>

        <div class="input-field">
          <div class="title" style="font-weight: bold; font-size: 16px">
            Câu trả lời
          </div>
          <QuestionAnswer
            :key="'update-' | randomKey"
            :answers="temp.answers"
            :rightAnswer="temp.right"
            :questionType="temp.type"
            :fakeChoices="temp.fakeChoices"
            :preview="false"
            @updateAnswer="handleUpdateAnswer"
            @updateRightAnswer="handleUpdateRightAnswer"
            @updateFakeChoiceToParent="handleUpdateFakeChoiceToParent"
          />
        </div>
      </md-dialog-content>
    </md-dialog>

    <md-dialog
      :md-active.sync="questionGroupDialog.visible"
      class="question-group-dialog"
    >
      <md-dialog-title>
        <div class="title">{{ $t('QUESTION_GROUP') }}</div>
        <BaseButton :label="$t('SAVE')" @click="updateQuestionGroup" />
      </md-dialog-title>
      <md-dialog-content v-loading="questionGroupDialog.loading">
        <div class="input-field">
          <BaseInputQuestionText
            placeholder="Tiêu đề nhóm câu hỏi"
            label="Tiêu đề nhóm câu hỏi"
            v-model="temp.title"
          />
        </div>
        <div class="input-field">
          <BaseInputQuestionTextArea
            label="Nội dung nhóm câu hỏi"
            v-model="temp.content"
          />
          <!-- <div class="title" style="font-weight: bold; font-size: 16px">
            Nội dung nhóm câu hỏi
          </div>
          <Tinymce v-model="temp.content" :height="300" /> -->
        </div>
        <div class="md-layout md-gutter">
          <div class="md-layout-item">
            <BaseInputQuestionFile
              label="Hình ảnh"
              accept="image/*"
              :loading.sync="uploadLoading.imgUrl"
              v-model="temp.imgUrl"
              iconClass="image"
              :storageClass="config.storageClass"
              :file="file.imgUrl"
              @file-selected="
                (val) => {
                  handleUpload('imgUrl', val)
                }
              "
              @clear="clearFile('imgUrl')"
            />
          </div>
          <div class="md-layout-item">
            <BaseInputQuestionFile
              label="Âm thanh"
              accept="audio/*"
              :loading.sync="uploadLoading.audioUrl"
              v-model="temp.audioUrl"
              iconClass="audio"
              :storageClass="config.storageClass"
              :file="file.audioUrl"
              @file-selected="
                (val) => {
                  handleUpload('audioUrl', val)
                }
              "
              @clear="clearFile('audioUrl')"
            />
          </div>
        </div>
      </md-dialog-content>
    </md-dialog>

    <BaseConfirmDialog
      :active.sync="deleteDialog.active"
      :title="deleteDialog.title"
      :content="deleteDialog.content"
      :loading="deleteDialog.loading"
      @close="handleCloseDeleteDialog"
      @cancel="handleCloseDeleteDialog"
      @confirm="deleteDialog.actionMethod(deleteDialog.data)"
    />
  </div>
</template>

<script>
import _ from 'lodash'
import { mapGetters } from 'vuex'
import examVaultApi from '@/api/exam-vault'
import { validUrl } from '@/utils/validate'
import QuestionAnswer from '@/components/question/QuestionAnswer.vue'
import { questionTypeFilter } from '@/filters/index'
// import Tinymce from '@/components/Tinymce'

export default {
  components: {
    QuestionAnswer
    // Tinymce
  },
  data() {
    return {
      questionTypeFilter,
      exam: {},
      loading: false,
      homeworkLoading: false,
      method: 'create',
      questionGroupDialog: {
        visible: false,
        method: '',
        loading: false
      },
      questionDialog: {
        visible: false,
        method: '',
        loading: false
      },
      temp: {},
      file: {
        imgUrl: '',
        audioUrl: ''
      },
      uploadLoading: {
        imgUrl: false,
        audioUrl: false
      },
      currentQuestionGroup: null,
      currentQuestion: null,
      deleteDialog: {
        active: false,
        title: '',
        content: '',
        loading: false,
        data: null
      },
      questionGroups: [],
      questions: []
    }
  },
  computed: {
    ...mapGetters({
      config: 'config'
    })
  },
  watch: {
    $route: {
      handler: async function () {
        this.loading = true

        if (this.$route?.params?.examVaultId) {
          this.exam = await this.$store.dispatch(
            'exam-vault/fetchExamById',
            this.$route.params.examVaultId
          )

          if (!this.exam) {
            return
          }

          this.$nextTick(() => {
            this.$set(this, 'homeworkTemp', _.cloneDeep(this.exam))
          })

          const questionGroups = await this.$store.dispatch(
            'question/GetEVQuestionGroups',
            {
              query: {
                examVaultId: this.exam._id
              }
            }
          )

          this.commitSetQuestionGroups(questionGroups)
          this.commitSetQuestions([])

          this.method = 'update'
        }

        this.loading = false
      },
      immediate: true,
      deep: true
    },
    'temp.type': {
      handler(val, oldVal) {
        if (this.isCloneQuestion) {
          this.isCloneQuestion = false
          return
        }
        switch (val) {
          case 'MultipleChoice':
            this.temp.answers = []
            delete this.temp.right
            break
          case 'ShortAnswer':
            this.temp.right = []
            delete this.temp.answers
            break
          case 'StringSorting':
            this.temp.right = ''
            delete this.temp.answers
            break
          case 'Speaking':
            this.temp.right = ''
            delete this.temp.answers
            break
          case 'Classification':
            this.temp.right = []
            this.temp.answers = {
              list: [],
              classificationFields: []
            }
            break
          case 'UnderlineWord':
            this.temp.right = []
            this.temp.answers = {
              type: '',
              phrase: ''
            }
            break
          case 'Matching':
            this.temp.right = []
            this.temp.answers = {
              list: [],
              classificationFields: []
            }
            break
          case 'WordSearch':
            this.temp.right = []
            this.temp.answers = {
              w: 0,
              h: 0,
              crossword: []
            }
            break
          case 'CompleteSentence':
            this.temp.right = []
            this.temp.answers = {
              phrase: ''
            }
            this.temp.fakeChoices = {}
            break
        }
      },
      immediate: true
    }
  },
  methods: {
    commitSetQuestionGroups(data) {
      this.questionGroups = data
    },

    commitCreateQuestionGroup(data) {
      this.questionGroups.push(data)
    },

    commitUpdateQuestionGroup(data) {
      const index = _.findIndex(this.questionGroups, (qg) => {
        return qg._id.toString() === data._id.toString()
      })

      if (index !== -1) {
        this.questionGroups.splice(index, 1, data)
      }
    },

    commitDeleteQuestionGroup(data) {
      const index = _.findIndex(this.questionGroups, (qg) => {
        return qg._id.toString() === data._id.toString()
      })

      if (index !== -1) {
        this.questionGroups.splice(index, 1)
      }
    },

    commitSetQuestions(data) {
      this.questions = data
    },

    commitCreateQuestion(data) {
      this.questions.push(data)
    },

    commitUpdateQuestion(data) {
      const index = _.findIndex(this.questions, (q) => {
        return q._id.toString() === data._id.toString()
      })

      if (index !== -1) {
        this.questions.splice(index, 1, data)
      }
    },

    commitDeleteQuestion(data) {
      const index = _.findIndex(this.questions, (q) => {
        return q._id.toString() === data._id.toString()
      })

      if (index !== -1) {
        this.questions.splice(index, 1)
      }
    },

    handleUpdateAnswer(type, answers) {
      this.$set(this.temp, 'answers', answers)
    },

    handleUpdateFakeChoiceToParent(type, fakeChoices) {
      this.$set(this.temp, 'fakeChoices', fakeChoices)
    },

    handleUpdateRightAnswer(type, right) {
      this.$set(this.temp, 'right', right)
    },

    async handleSelectQuestionGroup(item) {
      this.commitSetQuestions([])
      this.$set(this, 'currentQuestionGroup', _.cloneDeep(item))
      this.$set(this, 'currentQuestion', null)
      const questions = await this.$store.dispatch('question/GetEVQuestions', {
        params: {
          questionGroupId: item._id
        }
      })
      this.commitSetQuestions(questions)
      if (questions.length > 0) {
        this.handleSelectQuestion(questions[0])
      }
    },

    async handleSelectQuestion(item) {
      this.$set(this, 'currentQuestion', _.cloneDeep(item))
    },

    async updateQuestionGroup() {
      try {
        this.questionGroupDialog.loading = true
        if (this.questionGroupDialog.method === 'create') {
          const questionGroup = await this.$store.dispatch(
            'question/CreateEVQuestionGroup',
            {
              query: {
                examVaultId: this.temp.examVaultId
              },
              body: this.temp,
              params: {}
            }
          )

          this.commitCreateQuestionGroup(questionGroup)
          this.handleSelectQuestionGroup(questionGroup)
        } else {
          const questionGroup = await this.$store.dispatch(
            'question/UpdateEVQuestionGroup',
            {
              query: {},
              body: this.temp,
              params: {
                questionGroupId: this.temp._id
              }
            }
          )

          this.commitUpdateQuestionGroup(questionGroup)
          this.handleSelectQuestionGroup(questionGroup)
        }

        this.questionGroupDialog.loading = false
        this.questionGroupDialog.visible = false

        this.$notify({
          type: 'success',
          title: this.$t('M_TITLE_SUCCESS'),
          message: `${
            this.questionGroupDialog.method === 'create' ? 'Thêm' : 'Cập nhật'
          } nhóm câu hỏi thành công`
        })
      } catch (error) {
        this.questionGroupDialog.loading = false
      }
    },

    validMatchingQuestion(answers) {
      let ok = true
      if (
        answers &&
        answers.classificationFields &&
        answers.classificationFields.length > 0
      ) {
        answers.classificationFields.forEach((item) => {
          if (!validUrl(item)) {
            ok = false
          }
        })
      }

      return ok
    },

    async updateQuestion() {
      try {
        this.questionDialog.loading = true
        if (this.questionDialog.method === 'create') {
          const question = await this.$store.dispatch(
            'question/CreateEVQuestion',
            {
              query: {
                examVaultId: this.exam._id
              },
              body: this.temp,
              params: {
                questionGroupId: this.currentQuestionGroup._id
              }
            }
          )

          this.commitCreateQuestion(question)
          this.currentQuestion = question
        } else {
          const question = await this.$store.dispatch(
            'question/UpdateEVQuestion',
            {
              query: {
                examVaultId: this.exam._id
              },
              body: this.temp,
              params: {
                questionGroupId: this.currentQuestionGroup._id,
                questionId: this.temp._id
              }
            }
          )

          this.commitUpdateQuestion(question)
          this.currentQuestion = question
        }

        this.questionDialog.loading = false
        this.questionDialog.visible = false
        this.$notify({
          type: 'success',
          title: this.$t('M_TITLE_SUCCESS'),
          message: `${
            this.questionDialog.method === 'create' ? 'Thêm' : 'Cập nhật'
          } câu hỏi thành công`
        })
      } catch (error) {
        this.questionDialog.loading = false
      }
    },

    handleDelete(type, item) {
      if (type === 'questionGroup') {
        Object.assign(this.deleteDialog, {
          active: true,
          title: 'Xoá nhóm câu hỏi',
          content: `Bạn có muốn xoá nhóm câu hỏi: ${item.title} không?`,
          data: item,
          actionMethod: this.deleteQuestionGroup
        })
      }

      if (type === 'question') {
        Object.assign(this.deleteDialog, {
          active: true,
          title: 'Xoá câu hỏi',
          content: `Bạn có muốn xoá câu hỏi này không?`,
          data: item,
          actionMethod: this.deleteQuestion
        })
      }
    },

    handleCloseDeleteDialog() {
      Object.assign(this.deleteDialog, {
        loading: false,
        active: false
      })
    },

    async deleteQuestionGroup(questionGroup) {
      await this.$store.dispatch('question/DeleteEVQuestionGroup', {
        query: {
          examVaultId: this.exam._id
        },
        params: {
          questionGroupId: questionGroup._id
        }
      })

      this.commitDeleteQuestionGroup(questionGroup)

      this.$notify({
        type: 'success',
        title: this.$t('M_TITLE_SUCCESS'),
        message: 'Xoá nhóm câu hỏi thành công'
      })

      this.handleCloseDeleteDialog()
    },

    async deleteQuestion(question) {
      await this.$store.dispatch('question/DeleteEVQuestion', {
        query: {
          examVaultId: this.exam._id
        },
        params: {
          questionGroupId: this.currentQuestionGroup._id,
          questionId: question._id
        }
      })

      this.commitDeleteQuestion(question)

      this.$notify({
        type: 'success',
        title: this.$t('M_TITEV_SUCCESS'),
        message: 'Xoá câu hỏi thành công'
      })

      this.handleCloseDeleteDialog()
    },

    handleUpdateQuestion(method, question) {
      if (!this.currentQuestionGroup) {
        this.$notify({
          type: 'error',
          title: 'Lỗi',
          message: 'Vui lòng chọn nhóm câu hỏi'
        })
        return
      }

      if (question) {
        this.isCloneQuestion = true
        this.temp = _.cloneDeep(question)
        this.file = {
          imgUrl: question.imgUrl,
          audioUrl: question.audioUrl
        }
      } else {
        this.temp = {
          questionGroupId: this.currentQuestionGroup._id
        }
        this.file = {
          imgUrl: '',
          audioUrl: ''
        }
      }

      Object.assign(this.questionDialog, {
        visible: true,
        method: method,
        loading: false
      })
    },

    handleUpdateQuestionGroup(method, questionGroup) {
      if (questionGroup) {
        this.temp = _.cloneDeep(questionGroup)
        this.file = {
          imgUrl: questionGroup.imgUrl,
          audioUrl: questionGroup.audioUrl
        }
      } else {
        this.temp = {
          examVaultId: this.exam._id
        }
        this.file = {
          imgUrl: '',
          audioUrl: ''
        }
      }

      Object.assign(this.questionGroupDialog, {
        visible: true,
        method: method,
        loading: false
      })
    },

    async handleUpload(key, files) {
      if (this.config.storageClass === 1) {
        await this.$store.dispatch('media/GetSharedLink', {
          fileId: files.id,
          accessToken: this.googleDrive.accessToken
        })
        this.$set(this.temp, key, files.id)
        this.$set(this.file, key, files)
        return
      }

      if (files.length > 0) {
        await this.uploadAttachment(files[0], key)
      }
    },

    async uploadAttachment(file, key) {
      if (this.uploadLoading[key]) {
        this.$notify({
          type: 'warning',
          title: 'Lỗi',
          message: 'Vui lòng đợi tải lên các tập tin trước đó hoàn tất.'
        })

        return
      }

      if (!this.exam) {
        this.$notify({
          type: 'warning',
          title: 'Vui lòng thử lại sau giây lát'
        })
        return
      }

      this.$set(this.uploadLoading, key, true)
      try {
        const response = await examVaultApi.uploadAttachment({
          file,
          examVaultId: this.exam._id
        })

        this.$set(this.temp, key, '/' + response.data.key)

        this.$set(this.file, key, this.getFileName(response.data.key))
      } catch (error) {
        console.log(error)
      }
      this.$set(this.uploadLoading, key, false)
    },

    clearFile(key) {
      this.$set(this.file, key, null)
      this.$set(this.temp, key, null)
    },

    getFileName(url) {
      if (!url) {
        return ''
      }

      let paths = url.split('/')
      paths = paths[paths.length - 1].split('?')[0]
      return paths
    },

    goBack() {
      this.$router.push({
        name: 'ExamVault',
        params: {}
      })
    }
  },
  created() {}
}
</script>

<style lang="scss">
.question-homework {
  height: 100%;
  background: #fff;

  .header {
    padding: 6px 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: space-between;

    .right-hw-btn,
    .left-hw-btn {
      float: right;
      display: flex;
      align-items: center;
    }

    .right-hw-btn {
      padding-right: 32px;
      .homework-edit {
        display: flex;
        align-items: center;
        cursor: pointer;
        padding: 6px;
        color: #333;
        border-radius: 5px;
      }
      .homework-edit:hover {
        background: #1cab36 !important;
        color: #fff !important;
        .md-icon {
          color: #fff !important;
        }
      }
      .md-icon {
        font-size: 14px !important;
      }
      .label {
        font-size: 14px;
        font-weight: bold;
      }
    }
  }

  .homework-title {
    font-size: 24px;
    font-weight: bold;
    color: #333;
    display: flex;
    align-items: center;
    padding-right: 12px;
    cursor: pointer;
  }

  .question-group-list,
  .question-list,
  .preview-section {
    height: calc(100vh - 60px);
  }

  .question-group-list,
  .question-list {
    border-right: 1px solid rgba(0, 0, 0, 0.2);
    position: relative;

    .questions,
    .question-groups {
      height: calc(100vh - 60px - 60px);
      overflow-y: auto;

      .q-item:hover {
        background: #e6fbea;
      }

      .q-item-selected {
        background: #e6fbea !important;
      }

      .q-item {
        width: 100%;
        font-size: 14px;
        font-weight: bold;
        height: 50px;
        padding: 4px 8px;
        display: flex;
        cursor: pointer;

        .title {
          width: calc(100% - 80px);
          display: flex;
          align-items: center;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .settings {
          padding-left: 10px;
          width: 70px;
          display: flex;
          justify-content: space-evenly;
          align-items: center;
          .md-icon {
            font-size: 12px;
            color: rgba(0, 0, 0, 0.4);
            cursor: pointer;
          }
        }
      }
    }

    .add-question-group-btn,
    .add-question-btn {
      height: 60px;
      position: absolute;
      bottom: 0;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      border-top: 1px solid rgba(0, 0, 0, 0.2);
      background-color: #fff;

      .add-icon {
        background: #1cab36;
        border-radius: 50%;
        padding: 5px;
        margin-right: 20px;

        .md-icon {
          color: #fff;
        }
      }

      color: #1cab36;
      font-weight: bold;
      font-size: 16px;
    }
  }

  .preview-section {
    overflow-y: auto;
    padding: 0 24px;
    .question-preview,
    .question-group-preview {
      padding: 12px;
      text-align: left;
      .title {
        font-size: 16px;
        font-weight: bold;
        color: #1cab36;
      }
      .content {
        padding-top: 12px;
        padding-bottom: 24px;
      }

      .audio-preview {
        display: flex;
        justify-content: center;
        align-items: center;

        audio {
          width: 100%;
          height: 50px;
        }
      }
    }
  }
}

.question-group-dialog {
  .md-dialog-container {
    max-width: 60%;
  }
  .md-dialog-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    margin: 12px 24px 24px;
    padding: 0;
  }
}
</style>
