import * as API from '@/api/question'
import _ from 'lodash'

const state = {
  currentHomework: null,
  currentQuestion: null,
  currentQuestionGroup: null,
  questions: [],
  questionGroups: []
}

const mutations = {
  SET_CURRENT_HOMEWORK: (state, data) => {
    state.currentHomework = _.cloneDeep(data)
  },

  SET_CURRENT_QUESTION_GROUP: (state, data) => {
    state.questionGroup = _.cloneDeep(data)
  },

  SET_CURRENT_QUESTION: (state, data) => {
    state.question = _.cloneDeep(data)
  },

  SET_QUESTION_GROUPS: (state, data) => {
    state.questionGroups = data
  },

  NEW_QUESTION_GROUP: (state, data) => {
    state.questionGroups.push(data)
  },

  UPDATE_QUESTION_GROUP: (state, data) => {
    const index = _.findIndex(state.questionGroups, (qg) => {
      return qg._id.toString() === data._id.toString()
    })

    if (index !== -1) {
      state.questionGroups.splice(index, 1, data)
    }
  },

  DELETE_QUESTION_GROUP: (state, data) => {
    const index = _.findIndex(state.questionGroups, (qg) => {
      return qg._id.toString() === data._id.toString()
    })

    if (index !== -1) {
      state.questionGroups.splice(index, 1)
    }
  },

  SET_QUESTIONS: (state, data) => {
    state.questions = data
  },

  NEW_QUESTION: (state, data) => {
    state.questions.push(data)
  },

  UPDATE_QUESTION: (state, data) => {
    const index = _.findIndex(state.questions, (q) => {
      return q._id.toString() === data._id.toString()
    })

    if (index !== -1) {
      state.questions.splice(index, 1, data)
    }
  },

  DELETE_QUESTION: (state, data) => {
    const index = _.findIndex(state.questions, (q) => {
      return q._id.toString() === data._id.toString()
    })

    if (index !== -1) {
      state.questions.splice(index, 1)
    }
  }
}

const actions = {
  CreateQuestionGroupsBulk({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.createQuestionGroupsBulk(data)
        .then((response) => {
          resolve(true)
        })
        .catch(reject)
    })
  },

  GetQuestionGroups({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.getQuestionGroups(data)
        .then((response) => {
          const questionGroups = response.data.questionGroups
          // commit('SET_QUESTION_GROUPS', questionGroups)
          resolve(questionGroups)
        })
        .catch(reject)
    })
  },

  CreateQuestionGroup({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.createQuestionGroup(data)
        .then((response) => {
          const questionGroup = response.data.questionGroup
          // commit('NEW_QUESTION_GROUP', questionGroup)
          resolve(questionGroup)
        })
        .catch(reject)
    })
  },

  UpdateQuestionGroup({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.updateQuestionGroup(data)
        .then((response) => {
          const questionGroup = response.data.questionGroup
          // commit('UPDATE_QUESTION_GROUP', questionGroup)
          resolve(questionGroup)
        })
        .catch(reject)
    })
  },

  DeleteQuestionGroup({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.deleteQuestionGroup(data)
        .then((response) => {
          const questionGroup = response.data.questionGroup
          // commit('DELETE_QUESTION_GROUP', questionGroup)
          resolve(questionGroup)
        })
        .catch(reject)
    })
  },

  GetQuestions({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.getQuestions(data)
        .then((response) => {
          const questions = response.data.questions
          // commit('SET_QUESTIONS', questions)
          resolve(questions)
        })
        .catch(reject)
    })
  },

  CreateQuestion({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.createQuestion(data)
        .then((response) => {
          const question = response.data.question
          // commit('NEW_QUESTION', question)
          resolve(question)
        })
        .catch(reject)
    })
  },

  UpdateQuestion({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.updateQuestion(data)
        .then((response) => {
          const question = response.data.question
          // commit('UPDATE_QUESTION', question)
          resolve(question)
        })
        .catch(reject)
    })
  },

  DeleteLEQuestion({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.deleteLEQuestion(data)
        .then((response) => {
          const question = response.data.question
          // commit('DELETE_QUESTION', question)
          resolve(question)
        })
        .catch(reject)
    })
  },

  CreateLEQuestionGroupBulk({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.createLEQuestionGroupBulk(data)
        .then((response) => {
          const questionGroups = response.data.questionGroups
          // commit('SET_QUESTION_GROUPS', questionGroups)
          resolve(questionGroups)
        })
        .catch(reject)
    })
  },

  GetLEQuestionGroups({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.getLEQuestionGroups(data)
        .then((response) => {
          const questionGroups = response.data.questionGroups
          // commit('SET_QUESTION_GROUPS', questionGroups)
          resolve(questionGroups)
        })
        .catch(reject)
    })
  },

  CreateLEQuestionGroup({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.createLEQuestionGroup(data)
        .then((response) => {
          const questionGroup = response.data.questionGroup
          // commit('NEW_QUESTION_GROUP', questionGroup)
          resolve(questionGroup)
        })
        .catch(reject)
    })
  },

  UpdateLEQuestionGroup({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.updateLEQuestionGroup(data)
        .then((response) => {
          const questionGroup = response.data.questionGroup
          // commit('UPDATE_QUESTION_GROUP', questionGroup)
          resolve(questionGroup)
        })
        .catch(reject)
    })
  },

  DeleteLEQuestionGroup({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.deleteLEQuestionGroup(data)
        .then((response) => {
          const questionGroup = response.data.questionGroup
          // commit('DELETE_QUESTION_GROUP', questionGroup)
          resolve(questionGroup)
        })
        .catch(reject)
    })
  },

  GetLEQuestions({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.getLEQuestions(data)
        .then((response) => {
          const questions = response.data.questions
          // commit('SET_QUESTIONS', questions)
          resolve(questions)
        })
        .catch(reject)
    })
  },

  CreateLEQuestion({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.createLEQuestion(data)
        .then((response) => {
          const question = response.data.questions[0]
          // commit('NEW_QUESTION', question)
          resolve(question)
        })
        .catch(reject)
    })
  },

  UpdateLEQuestion({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.updateLEQuestion(data)
        .then((response) => {
          const question = response.data?.question
          // commit('UPDATE_QUESTION', question)
          resolve(question)
        })
        .catch(reject)
    })
  },

  DeleteEVQuestion({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.deleteEVQuestion(data)
        .then((response) => {
          const question = response.data.question
          // commit('DELETE_QUESTION', question)
          resolve(question)
        })
        .catch(reject)
    })
  },

  CreateEVQuestionGroupBulk({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.createEVQuestionGroupBulk(data)
        .then((response) => {
          const questionGroups = response.data.questionGroups
          // commit('SET_QUESTION_GROUPS', questionGroups)
          resolve(questionGroups)
        })
        .catch(reject)
    })
  },

  GetEVQuestionGroups({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.getEVQuestionGroups(data)
        .then((response) => {
          const questionGroups = response.data.questionGroups
          // commit('SET_QUESTION_GROUPS', questionGroups)
          resolve(questionGroups)
        })
        .catch(reject)
    })
  },

  CreateEVQuestionGroup({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.createEVQuestionGroup(data)
        .then((response) => {
          const questionGroup = response.data.questionGroup
          // commit('NEW_QUESTION_GROUP', questionGroup)
          resolve(questionGroup)
        })
        .catch(reject)
    })
  },

  UpdateEVQuestionGroup({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.updateEVQuestionGroup(data)
        .then((response) => {
          const questionGroup = response.data.questionGroup
          // commit('UPDATE_QUESTION_GROUP', questionGroup)
          resolve(questionGroup)
        })
        .catch(reject)
    })
  },

  DeleteEVQuestionGroup({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.deleteEVQuestionGroup(data)
        .then((response) => {
          const questionGroup = response.data.questionGroup
          // commit('DELETE_QUESTION_GROUP', questionGroup)
          resolve(questionGroup)
        })
        .catch(reject)
    })
  },

  GetEVQuestions({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.getEVQuestions(data)
        .then((response) => {
          const questions = response.data.questions
          // commit('SET_QUESTIONS', questions)
          resolve(questions)
        })
        .catch(reject)
    })
  },

  CreateEVQuestion({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.createEVQuestion(data)
        .then((response) => {
          const question = response.data.questions[0]
          // commit('NEW_QUESTION', question)
          resolve(question)
        })
        .catch(reject)
    })
  },

  UpdateEVQuestion({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.updateEVQuestion(data)
        .then((response) => {
          const question = response.data?.question
          // commit('UPDATE_QUESTION', question)
          resolve(question)
        })
        .catch(reject)
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
