<script>
import _ from 'lodash'
export default {
  inheritAttrs: false,
  model: {
    event: 'update'
  },
  props: {
    value: {
      type: [Array, Number, String, Object],
      default: () => null
    },
    width: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: null
    },
    formType: {
      type: String,
      default: 'text'
    },
    type: {
      type: String,
      default: 'text'
    },
    label: {
      type: String,
      default: null
    },
    labelSize: {
      type: Number,
      default: null
    },
    placeholder: {
      type: String,
      default: ''
    },
    options: {
      type: Array,
      default: () => []
    },
    optKey: {
      type: String,
      default: '_id'
    },
    optValue: {
      type: String,
      default: 'name'
    },
    multiple: {
      type: Boolean,
      default: null
    },
    multipleLimit: {
      type: Number,
      default: null
    },
    enableSelectAll: {
      type: Boolean,
      default: true
    },
    clearable: {
      type: Boolean,
      default: false
    },
    placement: {
      type: String
    },
    fallbackPlacements: {
      type: Array,
      default: () => ['bottom-start', 'top-start', 'bottom-end', 'top-end']
    },
    selected: {
      type: Array,
      default: () => []
    },
    selectAllLabel: {
      type: String,
      default: 'Chọn tất cả'
    },
    prefixIcon: {
      type: String,
      default: null
    },
    suffixIcon: {
      type: String,
      default: null
    },
    iconColor: {
      type: String,
      default: null
    },
    selectAll: {
      type: Boolean,
      default: false
    },
    customStyle: {
      type: String,
      default: ''
    },
    isRemoteSearch: {
      type: Boolean,
      default: false
    },
    remoteSearch: {
      type: Function,
      default: () => {}
    },
    remoteImediate: {
      type: Boolean,
      default: false
    },
    remoteSearchQuery: {
      type: Object,
      default: () => {}
    },
    remoteSearchInitData: {
      type: Boolean,
      default: false
    },
    remoteSearchResKey: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      loading: false,
      selectAllTemp: false,
      rOptions: []
    }
  },

  computed: {
    selectOptions() {
      return this.isRemoteSearch ? this.rOptions : this.options
    }
  },

  watch: {
    remoteImediate: {
      handler() {
        this.handleRemoteSearch('')
      },
      immediate: true
    },
    options: {
      handler(data) {
        if (data) {
          this.$set(this, 'rOptions', data)
        }
      },
      immediate: true,
      deep: true
    },
    selectAll: {
      handler(data) {
        this.selectAllTemp = data
        if (data) {
          const val = this.options.map((opt) => {
            return opt[this.optKey]
          })
          this.$emit('update', val)
        }
      },
      immediate: true
    }
  },

  methods: {
    async handleRemoteSearch(searchTxt) {
      try {
        const q = this.remoteSearchQuery.query
          ? this.remoteSearchQuery.query
          : {}
        if (searchTxt) {
          q._search = searchTxt
        } else {
          delete q._search
        }

        if (this.remoteSearchInitData && !_.isNil(this.value)) {
          Object.assign(q, {
            ids: _.isArray(this.value) ? this.value : [this.value]
          })
        }

        Object.assign(this.remoteSearchQuery, q)
        this.loading = true
        const response = await this.remoteSearch(this.remoteSearchQuery)
        const remoteSearchData = this.remoteSearchResKey
          ? response.data[this.remoteSearchResKey]
          : response.data
        this.$set(this, 'rOptions', remoteSearchData)
        this.loading = false
      } catch (error) {
        // console.log(error)
        this.rOptions = []
        this.loading = false
      }
    },
    handleSelectAll() {
      if (this.value.length === this.selectOptions.length) {
        this.$emit('update', [])
        return
      }
      const val = this.selectOptions.map((opt) => {
        return opt[this.optKey]
      })
      this.$emit('update', val)
    }
  }
}
</script>

<template>
  <div class="base-input-select" :style="customStyle">
    <div
      v-if="label"
      class="input-label"
      style="text-align: left; margin-bottom: 8px; width: 100%"
    >
      <label
        style="color: #333; font-weight: bold; position: relative"
        :style="'font-size: ' + (labelSize ? labelSize : 14) + 'px'"
      >
        {{ label }}
      </label>
    </div>

    <md-field :md-clearable="!multiple && clearable">
      <md-icon
        v-if="prefixIcon"
        style="position: absolute; top: 7px; left: 10px"
        :style="iconColor ? 'color: ' + iconColor : ''"
        >{{ prefixIcon }}</md-icon
      >
      <!-- <md-select
        :disabled="disabled"
        v-if="!multiple && !isRemoteSearch"
        @input="$emit('update', $event)"
        v-on="$listeners"
        v-bind="$attrs"
        v-bind:value="value"
        :clearable="clearable"
        :placeholder="placeholder"
        :style="
          (formType == 'filter' ? 'border-radius: 4px;' : ';') +
          ('padding-left: ' +
            (prefixIcon ? '35px' : '16px') +
            '; padding-right: ' +
            (suffixIcon ? '35px;' : '16px;')) +
          (disabled ? 'opacity: 0.5; cursor: not-allowed' : '')
        "
      >
        <md-option
          v-for="opt in selectOptions"
          :key="opt[optKey] || opt"
          :value="opt[optKey] || opt"
        >
          {{ opt[optValue] || opt }}
        </md-option>
      </md-select> -->

      <el-select
        :disabled="disabled"
        v-if="!multiple && !isRemoteSearch"
        @input="$emit('update', $event)"
        v-on="$listeners"
        v-bind="$attrs"
        v-bind:value="value"
        :clearable="clearable"
        :placeholder="placeholder"
        :placement="placement"
        :fallback-placements="fallbackPlacements"
        :style="
          (formType == 'filter' ? 'border-radius: 4px;' : ';') +
          ('padding-left: ' +
            (prefixIcon ? '35px' : '16px') +
            '; padding-right: ' +
            (suffixIcon ? '35px;' : '6px;')) +
          (disabled ? 'opacity: 0.5; cursor: not-allowed' : '') +
          (width ? 'width: ' + width + '!important;' : '')
        "
      >
        <el-option
          v-for="opt in selectOptions"
          :key="opt[optKey] || opt"
          :label="opt[optValue] || opt"
          :value="opt[optKey] || opt"
        />
      </el-select>

      <el-select
        v-else
        :disabled="disabled"
        v-on="$listeners"
        v-bind="$attrs"
        v-bind:value="value"
        :multiple="multiple"
        :clearable="clearable"
        :multiple-limit="multipleLimit"
        @input="$emit('update', $event)"
        @focus="remoteImediate && remoteSearch ? handleRemoteSearch('') : ''"
        collapse-tags
        style="margin-left: 20px"
        :placeholder="placeholder"
        :placement="placement"
        :fallback-placements="fallbackPlacements"
        :loading="loading"
        :filterable="Boolean(remoteSearch)"
        :automatic-dropdown="true"
        remote
        :remote-method="handleRemoteSearch"
        :style="
          (formType == 'filter' ? 'border-radius: 4px;' : ';') +
          ('padding-left: ' +
            (prefixIcon ? '35px' : '16px') +
            '; padding-right: ' +
            (suffixIcon ? '35px;' : '4px;')) +
          (disabled ? 'opacity: 0.5; cursor: not-allowed' : '') +
          (width ? 'width: ' + width + '!important;' : '')
        "
        value-key="id"
      >
        <div
          v-if="multiple && enableSelectAll"
          class="md-layout button-all"
          :class="
            value && value.length === selectOptions.length ? 'selected-all' : ''
          "
        >
          <md-button
            @click="handleSelectAll"
            style="text-transform: none; float: left"
          >
            <span>{{ selectAllLabel }}</span>
          </md-button>
        </div>
        <hr v-if="multiple && enableSelectAll" />
        <el-option
          v-for="opt in selectOptions"
          :key="opt[optKey && optKey !== '' ? optKey : optValue] || opt"
          :label="opt[optValue] || opt"
          :value="opt[optKey] || opt"
        >
        </el-option>
      </el-select>
      <md-icon
        v-if="suffixIcon"
        style="position: absolute; top: 7px; right: 10px"
        :style="iconColor ? 'color: ' + iconColor : ''"
        >{{ suffixIcon }}</md-icon
      >
    </md-field>
  </div>
</template>

<style lang="scss">
.button-all {
  width: 100%;
  text-align: left;

  button {
    width: 100%;
    text-align: left;

    .md-button-content {
      width: 100%;
    }
  }
}
.button-all.selected-all {
  .md-ripple {
    padding: 0 12px;
  }
  color: #1cab36;
  .md-button-content {
    color: #1cab36;
    font-weight: 700;
  }
}
.button-all.selected-all:after {
  position: absolute;
  right: 20px;
  top: 20px;
  font-family: element-icons;
  content: '\e6da';
  font-size: 12px;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.base-input-select {
  .md-field {
    padding: 0;
    margin: 0;
    min-height: 42px;
  }
  .el-select {
    .el-input {
      .el-input__inner {
        border: none;
        height: 42px;
        padding: 0;
        display: block;
        flex: 1;
        border: none;
        background: none;
        transition: 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
        transition-property: font-size, padding-top, color;
        font-family: inherit;
        font-size: 16px;
        line-height: 42px;
      }
    }
    .el-select__tags {
      .el-tag.el-tag--info {
        position: relative;
      }
    }
    .el-tag__close.el-icon-close {
      position: absolute;
    }
  }
  .el-select,
  .md-select {
    background-color: #fff !important;
    // box-shadow: 0 8px 20px rgba($color: #000000, $alpha: 0.16) !important;
    border: 1px solid #dcdfe6 !important;
    border-radius: 4px;
    margin: 0 !important;
    // padding-left: 16px !important;
    // padding-right: 16px !important;
    width: 100% !important;
    height: 42px !important;
  }

  .el-select,
  .md-select .md-input {
    border: none;
    height: 42px;
  }

  .md-field:after {
    height: 0 !important;
    background-color: transparent !important;
  }

  .md-field::before {
    height: 0 !important;
  }

  .md-clearable.md-has-value {
    .md-select {
      .md-icon.md-icon-font.md-icon-image.md-theme-default {
        display: none;
      }
    }
    .md-clear {
      top: 5px;
      right: 10px;
    }
  }
}
@media only screen and (max-width: 320px) {
}
@media only screen and (max-width: 480px) {
}
@media only screen and (max-width: 600px) {
}
@media only screen and (max-width: 768px) {
}
@media only screen and (max-width: 900px) {
}
@media only screen and (max-width: 1024px) {
}
</style>
