import axios from 'axios'
import request from '@/utils/request'
import serialize from '@/utils/serialize'
const CancelToken = axios.CancelToken

export default {
  createHomework(data) {
    return request({
      url: '/exam-vault/homeworks',
      method: 'post',
      data
    })
  },

  listCategories(params) {
    return request({
      url: '/exam-vault/categories' + serialize(params),
      method: 'get'
    })
  },

  listRootCategories(params) {
    return request({
      url: '/exam-vault/categories/roots' + serialize(params),
      method: 'get'
    })
  },

  listCategoriesByRootId(rootId, params) {
    return request({
      url: `/exam-vault/categories/by-root/${rootId}` + serialize(params),
      method: 'get'
    })
  },

  createCategory(data) {
    return request({
      url: '/exam-vault/categories',
      method: 'post',
      data
    })
  },

  updateCategory(id, data) {
    return request({
      url: `/exam-vault/categories/${id}`,
      method: 'put',
      data
    })
  },

  deleteCategory(id) {
    return request({
      url: `/exam-vault/categories/${id}`,
      method: 'delete'
    })
  },

  uploadAttachment(data, handleProgress, cancel) {
    const upload = new FormData()
    upload.append('file', data.file)

    return request({
      url:
        `/exam-vault/${data.examVaultId}/upload` +
        serialize({
          name: data.file.name,
          size: data.file.size
        }),
      method: 'put',
      timeout: false,
      data: upload,
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      onUploadProgress: handleProgress,
      cancelToken: new CancelToken(function executor(c) {
        if (cancel) {
          cancel(c)
        }
      })
    })
  },

  getExams(filters = {}) {
    const query = {}
    for (const key in filters) {
      if (filters[key]) {
        query[key] = filters[key]
      }
    }
    return request({
      url: '/exam-vault' + serialize(query),
      method: 'get'
    })
  },

  getExamById(id) {
    return request({
      url: `/exam-vault/${id}/detail`,
      method: 'get'
    })
  },

  getExamConfig() {
    return request({
      url: '/exam-vault/config',
      method: 'get'
    })
  },

  createExam(examData) {
    return request({
      url: '/exam-vault',
      method: 'post',
      data: examData
    })
  },

  updateExam(examData) {
    return request({
      url: `/exam-vault/${examData._id}`,
      method: 'put',
      data: examData
    })
  },

  deleteExam(id) {
    return request({
      url: `/exam-vault/${id}`,
      method: 'delete'
    })
  }
}
