<template>
  <div class="exam-vault-categories" v-loading="loading">
    <!-- Root Categories Section -->
    <div class="root-categories" v-if="rootCategories.length">
      <el-button
        v-if="editable && editPermission"
        type="text"
        size="medium"
        class="add-root-category-btn"
        @click.prevent="handleAddRootCategory()"
      >
        <svg-icon icon-class="plus-circle" />
        {{ $t('ADD_ROOT_CATEGORY') }}
      </el-button>
      <el-tabs
        v-model="rootCategoryTab"
        @tab-click="handleSelectRootCategory"
        @edit="handleEditRootCategory"
        type="card"
        editable="editable"
      >
        <el-tab-pane
          v-for="category in rootCategories"
          :key="category._id"
          :label="category.name"
          :name="category._id"
        >
          <template #label>
            <span class="custom-tabs-label">
              <span>{{ category.name }}</span>
              <el-button
                v-if="editable && editPermission"
                type="text"
                size="mini"
                @click.prevent="handleEditCategory(category)"
                class="root-category-edit-btn"
                icon="el-icon-edit"
              ></el-button>
            </span>
          </template>

          <el-row :gutter="24">
            <el-col :span="6">
              <!-- Category Tree Section -->
              <div class="category-section">
                <div
                  class="tab-header"
                  style="
                    display: flex;
                    justify-content: flex-start;
                    padding-left: 30px;
                  "
                >
                  <el-button
                    v-if="editable && editPermission"
                    type="text"
                    size="medium"
                    @click.prevent="
                      handleAddRootCategory(selectedRootCategory._id)
                    "
                  >
                    <svg-icon icon-class="plus-circle" />
                    {{ $t('ADD_CATEGORY') }}
                  </el-button>
                </div>
                <el-tree
                  :data="categories"
                  :props="defaultProps"
                  node-key="_id"
                  default-expand-all
                  :expand-on-click-node="false"
                  :ref="'category_tree_' + category._id"
                >
                  <span
                    class="custom-tree-node"
                    slot-scope="{ data }"
                    @click="selectCategory(data)"
                  >
                    <span
                      class="category-name"
                      :class="{
                        active:
                          selectedCategory && selectedCategory._id === data._id
                      }"
                      >{{ data.name }}</span
                    >
                    <span
                      v-if="editable && editPermission"
                      class="node-actions"
                    >
                      <el-button
                        type="text"
                        size="mini"
                        @click.prevent="() => handleAddCategory(data)"
                      >
                        <i class="el-icon-plus"></i>
                      </el-button>
                      <el-button
                        type="text"
                        size="mini"
                        @click.prevent="() => handleEditCategory(data)"
                      >
                        <i class="el-icon-edit"></i>
                      </el-button>
                      <el-button
                        type="text"
                        size="mini"
                        @click.prevent="() => handleDeleteCategory(data)"
                      >
                        <i class="el-icon-delete"></i>
                      </el-button>
                    </span>
                  </span>
                </el-tree>
              </div>
            </el-col>

            <el-col :span="18">
              <!-- Exams Component Section -->
              <div class="exams-section">
                <ExamVaultComponent
                  :ref="'exam_vault_' + category._id"
                  :editable="editable"
                  :editPermission="editPermission"
                  :ownerCategory="ownerCategory"
                  :categoryId="selectedCategoryId"
                  @editExam="(event, data) => $emit('editExam', event, data)"
                  @deleteExam="$emit('deleteExam', $event)"
                  @assignHomework="$emit('assignHomework', $event)"
                />
              </div>
            </el-col>
          </el-row>
        </el-tab-pane>
      </el-tabs>
    </div>

    <el-empty v-else>
      <el-button
        type="primary"
        @click.prevent="handleAddRootCategory(null)"
        class="add-category-btn"
        >{{ $t('ADD_CATEGORY') }}</el-button
      >
    </el-empty>

    <!-- Dialog for adding/editing categories -->
    <el-dialog
      :title="dialogType === 'add' ? $t('ADD_CATEGORY') : $t('EDIT_CATEGORY')"
      :visible.sync="dialogVisible"
      width="50%"
      append-to-body
    >
      <el-form :model="categoryForm" label-width="200px">
        <el-form-item :label="$t('CATEGORY_NAME')" prop="name">
          <el-input v-model="categoryForm.name"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">{{ $t('CANCEL') }}</el-button>
        <el-button
          type="primary"
          @click.prevent="submitCategoryForm"
          :loading="isSubmitting"
          :disabled="isSubmitting"
        >
          {{ $t('CONFIRM') }}
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import examVaultApi from '@/api/exam-vault'
import ExamVaultComponent from './ExamVault.vue'
import { mapState } from 'vuex'

export default {
  name: 'ExamVaultCategories',
  components: {
    ExamVaultComponent
  },

  props: {
    ownerCategory: {
      type: String,
      required: true
    },
    editable: {
      type: Boolean,
      default: false
    }
  },

  watch: {
    ownerCategory: {
      handler: function () {
        this.fetchRootCategories()

        let ownerCategories = ['Teacher']
        if (this.orgCategory === 'school') {
          const userRoles = this.profile?.roles || []
          if (
            userRoles.includes('admin') ||
            userRoles.includes('super_admin')
          ) {
            ownerCategories = ['Teacher', 'School']
          }
        } else {
          ownerCategories = ['Group']
        }

        this.editPermission = ownerCategories.includes(this.ownerCategory)
      },
      immediate: true
    }
  },

  computed: {
    ...mapState('user', ['orgCategory', 'profile'])
  },

  data() {
    return {
      categories: [],
      rootCategories: [],
      editPermission: false,
      loading: false,
      isSubmitting: false,
      selectedCategory: null,
      selectedCategoryId: null,
      selectedRootCategory: null,
      rootCategoryTab: '',
      defaultProps: {
        children: 'children',
        label: 'name'
      },
      dialogVisible: false,
      dialogType: 'add', // 'add' or 'edit'
      categoryForm: {
        name: '',
        parentId: null
      },
      currentCategory: null
    }
  },

  methods: {
    handleEditRootCategory(tab, type) {
      if (type === 'add') {
        this.handleAddRootCategory(null)
      } else if (type === 'remove') {
        this.handleDeleteCategory({
          _id: tab
        })
      }
    },

    handleSelectRootCategory(tab) {
      this.rootCategoryTab = tab.name
      const category = this.rootCategories.find((cat) => cat._id === tab.name)
      if (category) {
        this.selectedRootCategory = category
        this.selectedCategory = null
        this.selectedCategoryId = null
        this.fetchCategoriesByRoot()
      }
    },

    async fetchRootCategories() {
      this.loading = true
      try {
        const response = await examVaultApi.listRootCategories({
          ownerCategory: this.ownerCategory
        })
        this.rootCategories = response.data || []
        if (this.rootCategories.length) {
          this.rootCategoryTab = this.rootCategories[0]._id
          this.selectedRootCategory = this.rootCategories[0]
          this.selectedCategory = null
          this.fetchCategoriesByRoot()
        } else {
          this.selectedRootCategory = null
          this.selectedCategory = null
        }
      } catch (error) {
      } finally {
        this.loading = false
      }
    },

    async fetchCategoriesByRoot() {
      if (!this.selectedRootCategory) {
        this.categories = []
        return
      }

      this.loading = true
      try {
        const response = await examVaultApi.listCategoriesByRootId(
          this.selectedRootCategory._id,
          {
            ownerCategory: this.ownerCategory
          }
        )
        this.categories = response.data || []

        // Restore selection after refresh if a category was selected
        if (this.selectedCategory) {
          // Find the category in the new data
          const findCategory = (categories, id) => {
            for (const category of categories) {
              if (category._id === id) return category
              if (category.children && category.children.length) {
                const found = findCategory(category.children, id)
                if (found) return found
              }
            }
            return null
          }

          const foundCategory = findCategory(
            this.categories,
            this.selectedCategory._id
          )
          if (foundCategory) {
            this.selectCategory(foundCategory)
          } else {
            this.selectedCategory = null
          }
        }
      } catch (error) {
        this.$message.error(this.$t('FAILED_TO_FETCH_CATEGORIES'))
        console.error('Failed to fetch categories:', error)
      } finally {
        this.loading = false
      }
    },

    handleAddRootCategory(parentId) {
      this.dialogType = 'add'
      this.categoryForm = {
        name: '',
        parentId
      }
      this.currentCategory = null
      this.dialogVisible = true
    },

    handleAddCategory(data) {
      this.dialogType = 'add'
      this.categoryForm = {
        name: '',
        parentId: data._id
      }
      this.currentCategory = data
      this.dialogVisible = true
    },

    handleEditCategory(data) {
      this.dialogType = 'edit'
      this.categoryForm = {
        name: data.name,
        parentId: data.parentId
      }
      this.currentCategory = data
      this.dialogVisible = true
    },

    async handleDeleteCategory(data) {
      try {
        await this.$confirm(
          this.$t('DELETE_CATEGORY_CONFIRMATION'),
          this.$t('WARNING'),
          {
            confirmButtonText: this.$t('CONFIRM'),
            cancelButtonText: this.$t('CANCEL'),
            type: 'warning'
          }
        )

        await examVaultApi.deleteCategory(data._id)
        this.$message.success(this.$t('CATEGORY_DELETED_SUCCESSFULLY'))
        this.fetchRootCategories()
        this.selectCategory(null)
      } catch (error) {
        if (error !== 'cancel') {
          // this.$message.error(this.$t('FAILED_TO_DELETE_CATEGORY'))
          console.error('Failed to delete category:', error)
        }
      }
    },

    async submitCategoryForm() {
      // Prevent duplicate submissions
      if (this.isSubmitting) {
        return
      }

      this.isSubmitting = true

      try {
        if (this.dialogType === 'add') {
          await examVaultApi.createCategory({
            ownerCategory: this.ownerCategory,
            name: this.categoryForm.name,
            parentId: this.categoryForm.parentId
          })
          this.$message.success(this.$t('CATEGORY_CREATED_SUCCESSFULLY'))
        } else {
          await examVaultApi.updateCategory(this.currentCategory._id, {
            ownerCategory: this.ownerCategory,
            name: this.categoryForm.name
          })
          this.$message.success(this.$t('CATEGORY_UPDATED_SUCCESSFULLY'))
        }

        this.dialogVisible = false
        if (this.categoryForm.parentId) {
          this.fetchCategoriesByRoot()
        } else {
          this.fetchRootCategories()
        }

        // Emit event to notify parent components
        this.$emit('category-updated')
      } catch (error) {
        // this.$message.error(
        //   this.dialogType === 'add'
        //     ? this.$t('FAILED_TO_CREATE_CATEGORY')
        //     : this.$t('FAILED_TO_UPDATE_CATEGORY')
        // )
        // console.error('Failed to submit category form:', error)
      } finally {
        // Reset the submitting flag after the operation completes
        this.isSubmitting = false
      }
    },

    // Add method to handle category selection
    selectCategory(category) {
      this.selectedCategory = category
      this.selectedCategoryId = category?._id

      // Refresh exams for the selected category - but add a small delay
      // This ensures that the categoryId prop is properly updated before refreshing
      if (category) {
        this.$nextTick(() => {
          this.refreshExams()
        })
      }

      // Emit the selected category to parent component
      this.$emit('select-category', category?._id)
    },

    // Add a method to refresh exams
    refreshExams() {
      // Fix: Get the reference properly and check if it exists and has refreshExams method
      const examRef = this.$refs[`exam_vault_${this.selectedRootCategory?._id}`]
      if (examRef) {
        // For array references (when inside v-for)
        if (Array.isArray(examRef)) {
          examRef.forEach((ref) => {
            if (ref && typeof ref.refreshExams === 'function') {
              ref.refreshExams()
            }
          })
        } else if (typeof examRef.refreshExams === 'function') {
          examRef.refreshExams()
        }
      }
    },

    refresh() {
      this.selectedCategory = null
    }
  }
}
</script>

<style lang="scss">
.exam-vault-categories {
  background-color: #fff;
  border-radius: 4px;
  padding: 16px;
  text-align: left;

  .el-tabs {
    .el-tabs__header {
      margin-bottom: 0px;
    }

    .el-tabs__new-tab {
      display: none;
    }

    .el-tabs__content {
      padding-top: 10px;
      border: 1px solid #e4e7ed;
      border-top: none;
    }

    .root-category-edit-btn {
      color: inherit;
      margin-left: 8px;
      font-size: 11px;
      height: 14px;
      width: 14px;
      border-radius: 50%;
      position: relative;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      opacity: 0; /* Hide by default */
    }

    .root-category-edit-btn:hover {
      background-color: #c0c4cc;
      color: #fff;
    }

    .el-tabs__item:hover .root-category-edit-btn,
    .el-tabs__item.is-active .root-category-edit-btn {
      opacity: 1; /* Show on hover or when tab is active */
    }
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;

    h3 {
      margin: 0;
      font-size: 18px;
    }
  }

  .custom-tree-node {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    padding-right: 8px;

    .category-name {
      cursor: pointer;
      padding: 2px 6px;
      border-radius: 3px;
      transition: all 0.2s;

      &:hover {
        background-color: #ecf5ff;
      }

      &.active {
        background-color: #1cab36;
        color: white;
      }
    }

    .node-actions {
      opacity: 0;
      transition: opacity 0.2s;

      .el-button {
        padding: 3px 5px;
      }
    }

    &:hover .node-actions {
      opacity: 1;
    }
  }

  .el-tree-node__content {
    height: 36px;

    &:hover {
      background-color: #f5f7fa;
    }
  }
}
</style>
