import { render, staticRenderFns } from "./ExamVault.vue?vue&type=template&id=cf64257c&scoped=true"
import script from "./ExamVault.vue?vue&type=script&lang=js"
export * from "./ExamVault.vue?vue&type=script&lang=js"
import style0 from "./ExamVault.vue?vue&type=style&index=0&id=cf64257c&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cf64257c",
  null
  
)

export default component.exports