import request from '@/utils/request'
import serialize from '@/utils/serialize'

export function getQuestionGroups(data) {
  return request({
    url: '/homework/question-groups' + serialize(data.query),
    method: 'get'
  })
}

export function createQuestionGroupsBulk(data) {
  return request({
    url: '/homework/question-groups/bulk',
    method: 'post',
    data: data
  })
}

export function createQuestionGroup(data) {
  return request({
    url: '/homework/question-groups' + serialize(data.query),
    method: 'post',
    data: data.body
  })
}

export function updateQuestionGroup(data) {
  return request({
    url: `/homework/question-groups/${data.params.questionGroupId}`,
    method: 'put',
    data: data.body
  })
}

export function deleteQuestionGroup(data) {
  return request({
    url:
      `/homework/question-groups/${data.params.questionGroupId}` +
      serialize(data.query),
    method: 'delete'
  })
}

export function getQuestions(data) {
  return request({
    url:
      `/homework/question-groups/${data.params.questionGroupId}/questions` +
      serialize(data.query),
    method: 'get'
  })
}

export function createQuestion(data) {
  return request({
    url:
      `/homework/question-groups/${data.params.questionGroupId}/questions` +
      serialize(data.query),
    method: 'post',
    data: data.body
  })
}

export function updateQuestion(data) {
  return request({
    url:
      `/homework/question-groups/${data.params.questionGroupId}/questions/${data.params.questionId}` +
      serialize(data.query),
    method: 'put',
    data: data.body
  })
}

export function deleteQuestion(data) {
  return request({
    url:
      `/homework/question-groups/${data.params.questionGroupId}/questions/${data.params.questionId}` +
      serialize(data.query),
    method: 'delete'
  })
}

export function getLEQuestionGroups(data) {
  return request({
    url: '/live-exam/question-groups' + serialize(data.query),
    method: 'get'
  })
}

export function createLEQuestionGroup(data) {
  return request({
    url: '/live-exam/question-groups' + serialize(data.query),
    method: 'post',
    data: data.body
  })
}

export function updateLEQuestionGroup(data) {
  return request({
    url: `/live-exam/question-groups/${data.params.questionGroupId}`,
    method: 'put',
    data: data.body
  })
}

export function deleteLEQuestionGroup(data) {
  return request({
    url:
      `/live-exam/question-groups/${data.params.questionGroupId}` +
      serialize(data.query),
    method: 'delete'
  })
}

export function getLEQuestions(data) {
  return request({
    url:
      `/live-exam/question-groups/${data.params.questionGroupId}/questions` +
      serialize(data.query),
    method: 'get'
  })
}

export function createLEQuestion(data) {
  return request({
    url:
      `/live-exam/question-groups/${data.params.questionGroupId}/questions` +
      serialize(data.query),
    method: 'post',
    data: data.body
  })
}

export function updateLEQuestion(data) {
  return request({
    url:
      `/live-exam/question-groups/${data.params.questionGroupId}/questions/${data.params.questionId}` +
      serialize(data.query),
    method: 'put',
    data: data.body
  })
}

export function deleteLEQuestion(data) {
  return request({
    url:
      `/live-exam/question-groups/${data.params.questionGroupId}/questions/${data.params.questionId}` +
      serialize(data.query),
    method: 'delete'
  })
}

export function createLEQuestionGroupBulk(data) {
  return request({
    url: '/live-exam/question-groups/bulk',
    method: 'post',
    data: data
  })
}

export function getEVQuestionGroups(data) {
  return request({
    url: '/exam-vault/question-groups' + serialize(data.query),
    method: 'get'
  })
}

export function createEVQuestionGroup(data) {
  return request({
    url: '/exam-vault/question-groups' + serialize(data.query),
    method: 'post',
    data: data.body
  })
}

export function updateEVQuestionGroup(data) {
  return request({
    url: `/exam-vault/question-groups/${data.params.questionGroupId}`,
    method: 'put',
    data: data.body
  })
}

export function deleteEVQuestionGroup(data) {
  return request({
    url:
      `/exam-vault/question-groups/${data.params.questionGroupId}` +
      serialize(data.query),
    method: 'delete'
  })
}

export function getEVQuestions(data) {
  return request({
    url:
      `/exam-vault/question-groups/${data.params.questionGroupId}/questions` +
      serialize(data.query),
    method: 'get'
  })
}

export function createEVQuestion(data) {
  return request({
    url:
      `/exam-vault/question-groups/${data.params.questionGroupId}/questions` +
      serialize(data.query),
    method: 'post',
    data: data.body
  })
}

export function updateEVQuestion(data) {
  return request({
    url:
      `/exam-vault/question-groups/${data.params.questionGroupId}/questions/${data.params.questionId}` +
      serialize(data.query),
    method: 'put',
    data: data.body
  })
}

export function deleteEVQuestion(data) {
  return request({
    url:
      `/exam-vault/question-groups/${data.params.questionGroupId}/questions/${data.params.questionId}` +
      serialize(data.query),
    method: 'delete'
  })
}

export function createEVQuestionGroupBulk(data) {
  return request({
    url: '/exam-vault/question-groups/bulk',
    method: 'post',
    data: data
  })
}
